import React, { useState } from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile, useToast } from 'hooks';
import { defineMessages, useIntl } from 'react-intl';
import Input from 'components/common/Input/Input';
import Button from 'components/common/Button/Button';
import { func } from 'prop-types';
import parseError from 'utils/parseError';
import celebritiesService from 'services/celebritiesService';
import Loading from 'components/common/Loading';

const messages = defineMessages({
  dmPlaceholder: { id: 'form.dmPlaceholder' },
  dmTitle: { id: 'form.dmTitle' },
  message: { id: 'form.dmMessage' }
});
const DmModal = ({ hide, handleCloseDropDown }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const { showToast } = useToast();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDmChange = e => {
    setMessage(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = { dm: { message } };
      await celebritiesService.sendDirectMessage(data);
      setLoading(false);
      showToast('DM successfully sent!');
      hide();
      handleCloseDropDown();
    } catch ({ response }) {
      setLoading(false);
      throw parseError(response);
    }
  };

  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        {intl.formatMessage(messages.dmTitle)}{' '}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <div className="dm-div">
          <span>{intl.formatMessage(messages.message)}</span>
          <div className="dm-form-fields">
            <Input
              name="dm"
              placeholder={intl.formatMessage(messages.dmPlaceholder)}
              onChange={handleDmChange}
            />
            <Button
              type="primary"
              labelId="form.shareBtn"
              onClick={handleSubmit}
              disabled={!message || loading}
            >
              {loading && <Loading type="ball-clip-rotate" />}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

DmModal.propTypes = {
  hide: func.isRequired,
  handleCloseDropDown: func.isRequired
};

export default DmModal;
