import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import VideoImg from 'assets/icons/Video/Video.png';
import ScheduleEventImg from 'assets/ScheduleEvent.png';
import { useClickOutside, useIsMobile, useNavigation } from 'hooks';
import { routesPaths } from 'constants/routesPaths';

const GoLiveModal = ({ hide, id }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  const { goTo } = useNavigation();
  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        Live Options <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        <ul>
          <li>
            <div className="d-flex">
              <div className="icon">
                <img src={VideoImg} alt="videoImg" width={20} height={20} />
              </div>
              Go Live now
            </div>
          </li>
          <li>
            <div
              style={{ cursor: 'pointer' }}
              className="d-flex"
              onClick={() => goTo(routesPaths.addEvent)}
            >
              <div>
                <div className="icon">
                  <img src={ScheduleEventImg} alt="clockImg" width={20} height={20} />
                </div>
                Schedule live event
              </div>
              {/* <button type="button">coming soon</button> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GoLiveModal;
