/* eslint-disable react/button-has-type */
import React, { useMemo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { shape } from 'prop-types';

import { celebrityShape } from 'constants/propTypesShapes';

import { getCelebrity } from 'state/actions/celebritiesActions';
import { useNavigation, useSession, useAnalytics, useClickOutside, useModal } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { BIO_DESCRIPTION_LENGTH, MIXPANEL_EVENTS } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import {
  routeWithProps,
  getFullName,
  checkSubscription,
  celebrityNameFontSize
} from 'utils/helpers';

import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import FollowButton from 'components/celebrity/FollowButton/FollowButton';
import SubscribeButton from 'components/membership/SubscribeButton/SubscribeButton';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import ShopButton from 'components/common/ShopButton/ShopButton';
import MoreText from 'components/common/MoreText/MoreText';
import { BackArrowIcon, OptionsIcon } from 'components/icons';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import useMembership from 'hooks/celebrities/useMembership';
import { isEmpty } from 'lodash';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import Modal from 'components/common/Modal/Modal';
import ReportModal from 'components/ReportModal/ReportModal';

const CLOSED = 0;
const OPENED = 1;

const CelebrityBanner = ({ celebrity }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { goBack, goTo } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);
  const {
    user,
    authenticated,
    user: { id: loggedId }
  } = useSession();
  const {
    profile: { memberships },
    requestProfile,
    loading
  } = useProfile();
  const { membershipPlans } = useMembership();
  const isLoggedUser = loggedId === celebrity.id;

  const { bannerUrl, profileImageUrl, firstName, lastName, bio, id, activeLiveEvent, shopifyLink } =
    celebrity || {};

  const showActionButton = useMemo(() => celebrity && user?.id !== id, [user, id, celebrity]);

  useEffect(() => {
    showActionButton && authenticated && requestProfile();
  }, []);

  const isSubscribed = useMemo(
    () => showActionButton && checkSubscription(memberships, celebrity.id),
    [memberships, celebrity, showActionButton]
  );

  const onWatchLive = () => {
    trackEvent(MIXPANEL_EVENTS.liveAvatar, { 'Creator Name': getFullName(firstName, lastName) });
    goTo(routeWithProps(routesPaths.live, { celebrityId: celebrity.id }));
  };

  const requestCelebrity = () => {
    dispatch(getCelebrity(id));
  };

  return (
    <div className="banner">
      <div className="banner-top-bar">
        <div className="back-button" onClick={goBack}>
          <BackArrowIcon />
          <h5 className="bold desktop-only">{intl.formatMessage({ id: 'general.back' })}</h5>
        </div>
      </div>
      <div className="banner-content">
        <div className="p2 mobile-only final-text">
          <MoreText text={bio} limitLength={BIO_DESCRIPTION_LENGTH} />
        </div>
        <div className="banner-content-left">
          <ProfilePicture
            profileImage={profileImageUrl}
            isLive={activeLiveEvent}
            onWatchLive={onWatchLive}
          />
          <div className="info-container">
            <div className={`section-title bold ${celebrityNameFontSize(firstName, lastName)}`}>
              {getFullName(firstName, lastName)}
            </div>
            <div className="p1 desktop-only">
              <MoreText text={bio} limitLength={BIO_DESCRIPTION_LENGTH} />
            </div>
          </div>
        </div>
        <div className="banner-content-right">
          <BackgroundOpacity background={bannerUrl} />
          <div className="gradient" />
          <div className="action-buttons">
            <div>
              {!!shopifyLink && (
                <ShopButton
                  shopifyLink={shopifyLink}
                  celebrityName={getFullName(firstName, lastName)}
                />
              )}
              {!isLoggedUser && (
                <div className="sharing-options" ref={ref}>
                  {optionsStatus == OPENED && (
                    <>
                      {!isLoggedUser && (
                        <button className="report-button" onClick={reportToggle}>
                          Report
                        </button>
                      )}
                    </>
                  )}
                  {(optionsStatus == CLOSED || optionsStatus == OPENED) && (
                    <IconWithBackground
                      icon={<OptionsIcon />}
                      onClick={() => setOptionsStatus(optionsStatus == OPENED ? CLOSED : OPENED)}
                      isSmall
                    />
                  )}
                  {reportShowing && (
                    <Modal
                      hide={reportToggle}
                      isShowing={reportShowing}
                      topModal
                      zIndex={zIndexModalThird}
                      className="original-modal"
                    >
                      <ReportModal title="Report User" type="isUser" id={celebrity.id} />
                    </Modal>
                  )}
                </div>
              )}
            </div>

            {showActionButton && !loading && (
              <div className="flex">
                {!isSubscribed && <FollowButton celebrity={celebrity} />}
                {!isEmpty(membershipPlans) && (
                  <SubscribeButton
                    celebrity={celebrity}
                    onSuccess={requestCelebrity}
                    smallBoldFont
                    hideCrownMobile
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CelebrityBanner.propTypes = {
  celebrity: shape(celebrityShape).isRequired
};

export default CelebrityBanner;
