export const customDropDownStyles = {
  control: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'black',
    color: 'white',
    minHeight: '55px',
    borderColor: '#5C5B5B',
    fontFamily: 'Raleway',
    '&:hover': {
      borderColor: '#5C5B5B' // Same as the normal border color
    }
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: 'white'
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'white',
    fontFamily: 'Raleway'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#D3DFF1' : 'white',
    color: 'black'
  })
};
