import React from 'react';
import { object } from 'prop-types';
import { convertDateFormatInStandard, convertUtcToLocalTime } from 'utils/helpers';

const EventAbout = ({ event }) => {
  const { description, eventDate, eventTime, duration, membersPrice, nonMembersPrice } = event;

  return (
    <div className="event-about scrollable-container">
      <div className="date-time-row">
        <div>
          <h5 className="title">Date</h5>
          <span className="date-time-span">{convertDateFormatInStandard(eventDate)}</span>
        </div>
        <div>
          <h5 className="title">Time</h5>
          <span>{convertUtcToLocalTime(eventTime)}</span>
        </div>
        <div>
          <h5 className="title">Duration</h5>
          <span className="date-time-span">{duration}</span>
        </div>
      </div>
      <div className="member-row">
        <div>
          <h5 className="title">Members Price</h5>
          <span className="date-time-span">${membersPrice}</span>
        </div>
        <div>
          <h5 className="title">Non Members Price</h5>
          <span className="date-time-span">${nonMembersPrice}</span>
        </div>
      </div>
      <div className="description-row">
        <h5 className="title">Description</h5>
        <span>{description}</span>
      </div>
    </div>
  );
};

EventAbout.propTypes = {
  event: object
};

export default EventAbout;
