import React from 'react';
import { useHistory } from 'react-router-dom';

import { legalTabs } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { getTabNameFromId } from 'utils/dataHelpers';
import { routeWithProps } from 'utils/helpers';

import PrivacyPolicy from 'components/legal/PrivacyPolicy';
import Terms from 'components/legal/Terms';

const { PRIVACY, TERMS, CONTENT } = legalTabs;

export default () => {
  const { replace } = useHistory();

  const renderContent = (tab, isModal = false) => {
    switch (tab) {
      case PRIVACY.id:
        return <PrivacyPolicy isModal={isModal} />;
      case TERMS.id:
        return <Terms />;
      case CONTENT.id:
        return window.open(process.env.CONTENT_PDF_LINK, '_blank');
      default:
        return null;
    }
  };

  const changeUrl = id => {
    const tab = id ? getTabNameFromId(legalTabs, id) : '';
    replace(routeWithProps(routesPaths.legal, { tab }));
  };

  return {
    renderContent,
    changeUrl
  };
};
