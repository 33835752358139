import React, { useEffect, useCallback, useMemo } from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useClickOutside, useAnalytics, useSession } from 'hooks';
import cn from 'classnames';

import MembershipBenefit from 'components/membership/MembershipBenefit/MembershipBenefit';
import MembershipHeader from 'components/membership/MembershipHeader/MembershipHeader';
import SubscribeBanner from 'components/membership/SubscribeBanner/SubscribeBanner';
import UnAuthMembershipHeader from 'components/membership/UnAuthMembershipHeader/UnAuthMembershipHeader';
import Video from 'components/icons/membership/Video';
import Bell from 'components/icons/membership/Bell';
import AdFree from 'components/icons/membership/AdFree';
import Lock from 'components/icons/membership/Lock';
import Loading from 'components/common/Loading';

import { getFullName, checkSubscription } from 'utils/helpers';

import useMembership from 'hooks/celebrities/useMembership';
import isEmpty from 'lodash/isEmpty';

import { MIXPANEL_EVENTS } from 'constants/constants';
import { celebrityShape } from 'constants/propTypesShapes';
import useProfile from 'hooks/users/useProfile';

export const membershipBenefits = [
  {
    icon: <Lock />,
    title: 'membership.firstBenefitTitle',
    description: 'membership.firstBenefitDescription'
  },
  {
    icon: <Video />,
    title: 'membership.secondBenefitTitle',
    description: 'membership.secondBenefitDescription'
  },
  {
    icon: <Bell />,
    title: 'membership.thirdBenefitTitle',
    description: 'membership.thirdBenefitDescription'
  },
  {
    icon: <AdFree />,
    title: 'membership.fourthBenefitTitle',
    description: 'membership.fourthBenefitDescription'
  }
];

const MembershipDetails = ({ celebrity, hide, togglePayments, redirectToAuth }) => {
  const ref = useClickOutside(hide);
  const intl = useIntl();
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();
  const {
    profile: { memberships }
  } = useProfile();

  const { loading, membershipPlans } = useMembership();

  const { firstName, lastName } = celebrity;
  const fullName = useMemo(() => getFullName(firstName, lastName), [firstName, lastName]);

  const isSubscribed = checkSubscription(memberships, celebrity.id);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.membershipView, {
      'Creator Name': fullName
    });
  }, []);

  const showPayments = useCallback(() => {
    if (authenticated) {
      togglePayments();
      hide();
    } else {
      redirectToAuth();
    }
  }, [authenticated]);

  if (loading || isEmpty(membershipPlans))
    return (
      <div className="membership">
        <Loading />
      </div>
    );

  // We have a unique plan for now.
  const planDetails = membershipPlans[0];

  return (
    <div ref={ref} className="membership">
      {authenticated ? (
        <MembershipHeader
          celebrity={celebrity}
          hide={hide}
          planDetails={planDetails}
          showPayments={showPayments}
          isSubscribed={isSubscribed}
        />
      ) : (
        <UnAuthMembershipHeader
          celebrity={celebrity}
          hide={hide}
          planDetails={planDetails}
          showPayments={showPayments}
        />
      )}
      <div className={cn('membership-content', { unauth: !authenticated })}>
        {authenticated && (
          <>
            {!isSubscribed && (
              <div className="membership-content-description p2">
                {intl.formatMessage({ id: 'membership.description' }, { name: fullName })}
              </div>
            )}
            <div className="membership-content-title">
              <h4 className="bold">{fullName}</h4>
              <h4 className="bold">{intl.formatMessage({ id: 'membership.celebrityBenefits' })}</h4>
            </div>
          </>
        )}
        {membershipBenefits.map(({ icon, title, description }, index) => (
          <MembershipBenefit
            key={`benefit-${index}`}
            icon={icon}
            title={intl.formatMessage({ id: title })}
            description={intl.formatMessage({ id: description }, { firstName })}
          />
        ))}
        {!isSubscribed && (
          <>
            <h4 className="membership-content-title small-margin bold">
              {intl.formatMessage({ id: 'membership.subscribe' })}
            </h4>
            <SubscribeBanner
              onClick={showPayments}
              planDetails={planDetails}
              className="subscribe-border"
              celebrityName={fullName}
            />
          </>
        )}
      </div>
    </div>
  );
};

MembershipDetails.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  togglePayments: func.isRequired,
  hide: func,
  redirectToAuth: func.isRequired
};

export default MembershipDetails;
