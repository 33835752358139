import React from 'react';
import { useIntl } from 'react-intl';
import { shape, func, string, number } from 'prop-types';

import Close from 'components/common/Close/Close';
import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';
import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { PURCHASE_TYPES } from 'constants/constants';
import { celebrityShape } from 'constants/propTypesShapes';

import useEpisode from 'hooks/useEpisode';

const BACKGROUND_OPACITY = 0.7;
const ICON_SIZE = 20;
const PROFILE_IMAGE_SIZE = 92;

const UnlockEpisodeHeader = ({
  celebrity,
  hide,
  showPayments,
  episode,
  price,
  membershipPrice
}) => {
  const { bannerUrl, profileImageUrl } = celebrity;
  const intl = useIntl();
  const { flowConfig } = useEpisode();

  const PURCHASE_MEMBERSHIP_AND_EPISODE =
    flowConfig.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE;
  const BUTTON_TITLE = PURCHASE_MEMBERSHIP_AND_EPISODE
    ? intl.formatMessage({ id: 'unlock.checkout' })
    : intl.formatMessage({ id: 'unlock.title' });

  return (
    <div className="unlock-episode-header">
      <div className="unlock-episode-header-celebrity">
        {!!bannerUrl && <BackgroundOpacity background={bannerUrl} opacity={BACKGROUND_OPACITY} />}
        <div className="unlock-episode-header-gradient" />
        <div className="unlock-episode-header-gradient-vertical desktop-only" />
        <div className="unlock-episode-header-celebrity-content">
          <ProfilePicture
            profileImage={profileImageUrl}
            height={PROFILE_IMAGE_SIZE}
            width={PROFILE_IMAGE_SIZE}
          />
          <div className="category-semibold celebrity-name">
            {!PURCHASE_MEMBERSHIP_AND_EPISODE ? (
              <>
                <span>{intl.formatMessage({ id: 'unlock.title' })}</span>
                <p className="episode-name">{episode}</p>
                <p className="h1 episode-price">{`$${price}`}</p>
              </>
            ) : (
              <>
                <h4 className="semibold title">
                  {intl.formatMessage(
                    { id: 'membership.subscribeCelebrity' },
                    { name: `${celebrity.firstName} ${celebrity.lastName}` }
                  )}
                </h4>
                <div className="p1">
                  <span className="bold title">+</span>
                  <br />
                  <span className="bold title">
                    {intl.formatMessage({ id: 'general.episode' })}:{' '}
                  </span>
                  <span className="title">{episode}</span>
                </div>
              </>
            )}
          </div>
          <div className="bottom-container">
            <div className="p2 description">
              {PURCHASE_MEMBERSHIP_AND_EPISODE
                ? intl.formatMessage({ id: 'membership.renew' }, { price: membershipPrice })
                : intl.formatMessage({ id: 'unlock.content.forever' })}
            </div>
            <Button
              icon={
                PURCHASE_MEMBERSHIP_AND_EPISODE && <Crown width={ICON_SIZE} height={ICON_SIZE} />
              }
              size={PURCHASE_MEMBERSHIP_AND_EPISODE ? 'small' : 'large'}
              type="primary"
              title={BUTTON_TITLE}
              onClick={showPayments}
            />
          </div>
        </div>
        <Close onClick={hide} />
      </div>
    </div>
  );
};

UnlockEpisodeHeader.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  hide: func.isRequired,
  episode: string.isRequired,
  price: number.isRequired,
  membershipPrice: number.isRequired,
  showPayments: func.isRequired
};

export default UnlockEpisodeHeader;
