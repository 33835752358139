import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useNavigation, useIsMobile } from 'hooks';
import { legalTabs } from 'constants/constants';
import { getIdFromTab, getLocaleFromId } from 'utils/dataHelpers';

import { BackArrowIcon } from 'components/icons';
import Tabs from 'components/common/Tabs/Tabs';
import useLegal from 'hooks/useLegal';
import { useParams } from 'react-router-dom';

const { PRIVACY } = legalTabs;

const LegalPage = () => {
  const intl = useIntl();

  const { tab } = useParams();

  const { changeUrl, renderContent } = useLegal();

  const isMobile = useIsMobile();

  const selectedTabId = useMemo(() => tab && getIdFromTab(legalTabs, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(selectedTabId || PRIVACY.id);

  const tabName = useMemo(() => getLocaleFromId(legalTabs, selectedTab), [selectedTab]);

  const { goBack } = useNavigation();

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  useEffect(() => setSelectedTab(selectedTabId), [selectedTabId]);

  const onTabChange = id => changeUrl(id);

  return (
    <div className="legal">
      <div className="banner-top-bar">
        <div className="back-button" onClick={goBack}>
          <BackArrowIcon />
          <h5 className="bold desktop-only">{intl.formatMessage({ id: 'general.back' })}</h5>
        </div>
        <h6 className="title mobile-only">{intl.formatMessage({ id: tabName })}</h6>
      </div>
      <div className="legal-content">
        {isMobile ? (
          renderContent(selectedTab)
        ) : (
          <>
            <div className="legal-content-left">
              <Tabs
                tabs={Object.values(legalTabs)}
                selectedTab={selectedTab}
                onTabSelect={onTabChange}
                isVertical
                className="privacy-policy-tabs"
              />
            </div>
            <div className="legal-content-right">{renderContent(selectedTab)}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default LegalPage;
