import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAnalytics } from 'hooks';
import { MEDIA_INQUIRIES_EMAIL, MIXPANEL_EVENTS } from 'constants/constants';
import {
  PRIVACY_POLICY,
  LEGEND,
  ADS_SETTINGS_LINK,
  ADS_SETTINGS_TEXT,
  ABOUT_ADS_LINK,
  ABOUT_ADS_TEXT,
  PRIVACY_POLICY_2ND_PART,
  SUPPORT_EMAIL
} from 'constants/legal';

import Hyperlink from 'components/common/Hyperlink/Hyperlink';

const PrivacyPolicy = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const settingsAd = <Hyperlink text={ADS_SETTINGS_TEXT} link={ADS_SETTINGS_LINK} />;
  const aboutAds = <Hyperlink text={ABOUT_ADS_TEXT} link={ABOUT_ADS_LINK} />;

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.privacyPolicy);
  }, []);

  return (
    <div className="privacy-policy">
      <h1 className="title desktop-only">{intl.formatMessage({ id: 'privacyPolicy.title' })}</h1>
      {PRIVACY_POLICY.map(({ title, body, googleAd, body2 }, index) => (
        <Fragment key={`privacy-${index}`}>
          <h3 className="legal-title">{title}</h3>
          <div className="body p2">
            {body}
            {googleAd && (
              <>
                {settingsAd}
                {body2}
                {aboutAds}
              </>
            )}
          </div>
        </Fragment>
      ))}
      <Hyperlink text={MEDIA_INQUIRIES_EMAIL} link={`mailto:${MEDIA_INQUIRIES_EMAIL}`} />
      <div className="body p2">{LEGEND}</div>
      {PRIVACY_POLICY_2ND_PART.map(({ title, body }, index) => (
        <Fragment key={index}>
          <h3 className="legal-title">{title}</h3>
          <div className="body p2">{body}</div>
        </Fragment>
      ))}
      <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} />
    </div>
  );
};

export default PrivacyPolicy;
