import React, { useRef, useEffect, useMemo } from 'react';
import { shape, string, arrayOf, oneOfType, func } from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { celebrityInfoShape } from 'constants/propTypesShapes';
import { useDisappearingControls } from 'hooks';

import MiscControls from 'components/Players/Controls/MiscControls';
import videojs from 'video.js';

const MiscPlayer = ({ celebrities, videoUrl, type, title, description, goBack }) => {
  const { controlsVisible, handleClick, handleMouseMove } = useDisappearingControls(true);
  const videoRef = useRef(null); // Reference to the video element
  const playerRef = useRef(null); // Reference to the Video.js player instance

  // Memoize the videoJsOptions to prevent reinitialization on each render
  const videoJsOptions = useMemo(
    () => ({
      controls: true,
      responsive: true,
      fluid: true,
      aspectRatio: isMobile ? '9:16' : '16:8',
      sources: [
        {
          src: videoUrl,
          type: type === null ? 'video/mp4' : type
        }
      ]
    }),
    [videoUrl]
  );

  useEffect(() => {
    const handleContextMenu = e => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    // Initialize Video.js player only once
    if (playerRef.current === null && videoRef.current) {
      playerRef.current = videojs(videoRef.current, videoJsOptions, function onPlayerReady() {
        console.log('Player is ready', playerRef.current, type);
        // Play the video if autoplay is needed
        playerRef.current.play();
      });
    } else if (playerRef.current) {
      // If videoUrl changes, update the source without reinitializing
      playerRef.current.src(videoJsOptions.sources);
    }

    // Cleanup to dispose of the player instance when the component unmounts
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [videoJsOptions]);

  return (
    <div
      className={cn('video-container misc-video-container', { 'no-pointer': isMobile })}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <video ref={videoRef} className="video-js vjs-default-skin" />
      {playerRef.current && (
        <MiscControls
          player={playerRef.current}
          celebrities={celebrities}
          title={title}
          description={description}
          goBack={goBack}
          controlsVisible={controlsVisible}
        />
      )}
    </div>
  );
};

MiscPlayer.propTypes = {
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  description: string,
  title: string,
  type: string,
  videoUrl: string.isRequired,
  goBack: func.isRequired
};

export default MiscPlayer;
