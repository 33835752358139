import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { combineReducers } from 'redux';
import localForage from 'localforage';

import { statusReducer } from '@rootstrap/redux-tools';
import session from 'state/reducers/sessionReducer';
import series from 'state/reducers/seriesReducer';
import celebrities from 'state/reducers/celebritiesReducer';
import categories from 'state/reducers/categoriesReducer';
import home from 'state/reducers/homeReducer';
import notifications from 'state/reducers/notificationsReducer';
import payments from 'state/reducers/paymentsReducer';
import liveEvents from 'state/reducers/liveEventsReducer';
import narratives from 'state/reducers/narrativesReducer';
import videoads from 'state/reducers/VideoAdsReducer';
import episodes from 'state/reducers/episodesReducer';
import seriesUpload from './seriesUploadReducer';
import adBannerReducer from './adBannerReducer';
import reactionReducer from './reactionReducer';
import eventReducer from './eventReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'info', 'user', 'token'],
  stateReconciler: autoMergeLevel2
};

const seriesPersistConfig = {
  key: 'series',
  storage: localForage,
  whitelist: ['keepWatchingList'],
  stateReconciler: autoMergeLevel2
};

const rootReducer = sessionConfig =>
  combineReducers({
    session: persistReducer(sessionConfig || sessionPersistConfig, session),
    series: persistReducer(seriesPersistConfig, series),
    celebrities,
    categories,
    home,
    notifications,
    payments,
    narratives,
    videoads,
    episodes,
    seriesUpload,
    actionStatus: statusReducer,
    liveEvents,
    adBannerReducer,
    reactionReducer,
    eventReducer
  });

export default rootReducer;
