import { createThunk } from '@rootstrap/redux-tools';
import EventService from 'services/eventService';
import parseError from 'utils/parseError';

export const addEvent = createThunk('ADD_EVENT', async (celebrityId, event) => {
  try {
    const { data } = await EventService.addEvent(celebrityId, event);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updateEvent = createThunk('UPDATE_EVENT', async (celebrityId, eventId, event) => {
  try {
    const { data } = await EventService.updateEvent(celebrityId, eventId, event);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const deleteEvent = createThunk('DELETE_EVENT', async (celebrityId, eventId) => {
  try {
    await EventService.deleteEvent(celebrityId, eventId);
  } catch ({ response }) {
    throw parseError(response);
  }
});
