/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Button from 'components/common/Button/Button';
import DateFilterIcon from 'assets/date-filter.png';
import FilterIcon from 'assets/filter.png';
import { useToggle } from 'hooks';
import DateDropdown from './DateDropDown';
import CategoryDropdown from './CategoryDropDown';

const SearchHeader = ({ value, handleFilterCall, handleCategoryFilterCall }) => {
  const [showDateDropdown, toggleDateDropdown] = useToggle(false);
  const [showCategoryDropdown, toggleShowCategoryDropdown] = useToggle(false);
  const searchParams = new URLSearchParams(location.search);
  const sortName = useState(searchParams.get('sort') || '');

  const handleCloseDateDropDown = () => {
    toggleDateDropdown();
  };

  const handleCategoryCloseDropDown = () => {
    toggleShowCategoryDropdown();
  };
  return (
    <div className="search-header">
      <div className="search-header-title">
        Search result for “<span style={{ color: '#2400FC' }}>{value}</span>”
      </div>
      <div className="button-container">
        <Button className="date-button" onClick={toggleDateDropdown}>
          <img height="20px" width="20px" src={DateFilterIcon} alt="date-filter" />
          {sortName || 'Sort By'}
          {showDateDropdown && (
            <DateDropdown
              handleFilterCall={handleFilterCall}
              handleCloseDropDown={handleCloseDateDropDown}
            />
          )}
        </Button>
        <Button className="category-button" onClick={toggleShowCategoryDropdown}>
          <img height="20px" width="20px" src={FilterIcon} alt="filter" />
          All Category
          {showCategoryDropdown && (
            <CategoryDropdown
              handleCategoryFilterCall={handleCategoryFilterCall}
              handleCategoryCloseDropDown={handleCategoryCloseDropDown}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

export default SearchHeader;
