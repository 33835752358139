/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { shape, bool, string, func } from 'prop-types';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { narrativeShape, celebrityInfoShape } from 'constants/propTypesShapes';
import { checkSubscription, getFullName, routeWithProps } from 'utils/helpers';
import useProfile from 'hooks/users/useProfile';

import NarrativesControls from 'components/Players/Controls/NarrativesControls';
import { ArrowIcon } from 'components/icons';

import { black40 } from 'styles/common/_constants.scss';
import { useAnalytics, useDisappearingControls } from 'hooks';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';
import ActionableContents from 'components/actionableContents/ActionableContents';
import useVideoPlayer from './useVideoPlayer';
import './videojs.css';

const NarrativesPlayer = React.memo(
  ({
    CurrentIndex,
    narrative: {
      link,
      title,
      description,
      mimeType,
      videoAds,
      widgetAds,
      id,
      likes,
      dislikes,
      favourites
    },
    className,
    play,
    indexId,
    celebrity,
    showAds,
    goToNext,
    goToPrevious,
    showPreviousArrow,
    showNextArrow,
    width,
    height
  }) => {
    const {
      profile: { memberships }
    } = useProfile();
    const { trackEvent } = useAnalytics();
    const history = useHistory();
    const isSubscribed = useMemo(() => checkSubscription(memberships, celebrity.id), [
      memberships,
      celebrity.id
    ]);
    const { handleMouseMove, controlsVisible, handleClick } = useDisappearingControls(true);
    const playerRef = useRef(null);
    const [isBlurred, setIsBlurred] = useState(false);

    const applyBlur = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.style.filter = 'blur(20px)';
      }
      if (playerRef.current) {
        playerRef.current.pause();
      }
      setIsBlurred(true);
    }, []);

    const removeBlur = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.style.filter = 'none';
      }
      if (playerRef.current) {
        playerRef.current.play();
      }
      setIsBlurred(false);
    }, []);

    const handleBlur = useCallback(() => {
      applyBlur();
    }, [applyBlur]);

    const handleUserInteraction = useCallback(() => {
      if (isBlurred) {
        removeBlur();
      }
    }, [isBlurred, removeBlur]);

    useEffect(() => {
      window.addEventListener('blur', handleBlur);

      return () => {
        window.removeEventListener('blur', handleBlur);
      };
    }, [handleBlur]);
    const getVideoType = useCallback(link => {
      const extension = link.split('.').pop();
      switch (extension) {
        case 'mp4':
          return 'video/mp4';
        case 'webm':
          return 'video/webm';
        case 'ogg':
          return 'video/ogg';
        case 'm3u8':
          return 'application/x-mpegURL';
        case 'x-msvideo':
          return 'video/x-msvideo';
        default:
          return 'video/mp4';
      }
    }, []);

    // const mimeType = null;
    const videoJsOptions = useMemo(
      () => ({
        autoplay: false,
        loop: false,
        controls: true,
        responsive: true,
        fluid: false,
        width,
        height,
        aspectRatio: isMobile ? '9:16' : '12:8',
        sources: [
          {
            src: link,
            type: mimeType === null ? 'video/mp4' : mimeType
          }
        ],
        tracks: {
          id,
          type: 'Narrative',
          celebrity,
          title
        }
      }),
      [link, isMobile, width, height]
    );

    const onPlayerReady = useCallback(
      player => {
        console.log('Player is ready', player, link, indexId);
        playerRef.current = player;
      },
      [link, indexId]
    );
    const handlePlayNextUpdate = useCallback(
      newPlayNext => {
        console.log('PlayNext updated:', newPlayNext);
      },
      [link, indexId]
    );
    const { videoRef, IsAds, AdsDetail } = useVideoPlayer({
      options: videoJsOptions,
      onReady: onPlayerReady,
      preRollSource: videoAds?.find(ad => ad.placementOption === 'before_video' && !ad.disable),
      postRollSource: videoAds?.find(ad => ad.placementOption === 'after_video' && !ad.disable),
      midRollSource: videoAds?.find(ad => ad.placementOption === 'custom_intervals' && !ad.disable),
      midRollInterval: videoAds?.find(
        ad => ad.placementOption === 'custom_intervals' && !ad.disable
      )?.specificInterval,
      customDurationAds: videoAds?.find(
        ad => ad.placementOption === 'specific_interval' && !ad.disable
      ),
      onPlayNextUpdate: handlePlayNextUpdate,
      link,
      mimeType
    });
    const trackTiming = useCallback(() => {
      const { firstName, lastName } = celebrity;
      if (playerRef.current) {
        trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
          Seconds: Math.round(playerRef.current.currentTime()), // Updated
          'Celebrity Name': getFullName(firstName, lastName),
          'Narrative Name': title,
          id,
          typeName: MIXPANEL_PARAMS.Narrative
        });
      }
    }, [celebrity, title, trackEvent]);

    const getNextOrPreviousIndex = useCallback((direction, currentId, indexList) => {
      const currentPosition = indexList.findIndex(item => item.id === currentId);
      if (currentPosition === -1) return null;
      if (direction === 'left') {
        return indexList[currentPosition === 0 ? indexList.length - 1 : currentPosition - 1].id;
      }
      if (direction === 'right') {
        return indexList[currentPosition === indexList.length - 1 ? 0 : currentPosition + 1].id;
      }
      return null;
    }, []);

    const NextNarrative = useCallback(
      direction => {
        const index = getNextOrPreviousIndex(direction, CurrentIndex, celebrity.narratives);
        history.push(
          routeWithProps(routesPaths.narratives, { celebrityId: celebrity.id, id: index })
        );
        window.location.reload();
      },
      [CurrentIndex, celebrity.id, celebrity.narratives, getNextOrPreviousIndex, history]
    );

    const goToNextNarrative = useCallback(() => {
      if (goToNext) {
        trackTiming();
        goToNext();
      }
    }, [goToNext, trackTiming]);

    const goToPreviousNarrative = useCallback(() => {
      if (goToPrevious) {
        trackTiming();
        goToPrevious();
      }
    }, [goToPrevious, trackTiming]);

    useEffect(() => {
      if (!play) {
        trackTiming();
      }
    }, [play, trackTiming]);

    const videoClicked = () => {
      if (IsAds && AdsDetail != null && AdsDetail.buttonText != null) {
        window.open(AdsDetail.actionButtonUrl, '_blank');
      }
    };

    return (
      <div
        className={cn(
          'video-container video-container-narratives visible',
          {
            'video-ad-container': widgetAds.length !== 0,
            'video-ad-container-without-widget': widgetAds.length === 0,
            'no-pointer': isMobile
          },
          className
        )}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        style={{
          position: 'relative',
          width: '100%'
        }}
      >
        <video
          ref={videoRef}
          width={width}
          height={height}
          onClick={videoClicked}
          id={indexId}
          className="video-js vjs-default-skin"
        />
        {isBlurred && (
          <div className="blur-overlay" onClick={handleUserInteraction}>
            <p>Click to resume video playback</p>
          </div>
        )}
        <div className="ad-overlay">
          {IsAds && AdsDetail != null && AdsDetail.buttonText != null ? (
            <div className="ad-content">
              <div className="product-name">{AdsDetail.title}</div>
              {AdsDetail.actionButtonUrl && (
                <button
                  className="buy-now-button"
                  onClick={() => {
                    window.open(AdsDetail.actionButtonUrl, '_blank');
                  }}
                >
                  {AdsDetail.buttonText}
                </button>
              )}
            </div>
          ) : null}
          {IsAds ? <div className="sponsored-label">Sponsored</div> : null}
        </div>
        {playerRef.current && play && (
          <>
            <NarrativesControls
              title={title}
              description={description}
              player={playerRef.current}
              celebrity={celebrity}
              goToNext={goToNextNarrative}
              controlsVisible={controlsVisible}
              mute
            />
            <div className="navigation-buttons desktop-only">
              {showNextArrow && (
                <div className="nav-button previous-button" onClick={() => NextNarrative('right')}>
                  <ArrowIcon width={25} height={10} type="left" color={black40} />
                </div>
              )}
              {showPreviousArrow && (
                <div className="nav-button next-button" onClick={() => NextNarrative('left')}>
                  <ArrowIcon width={25} height={10} type="right" color={black40} />
                </div>
              )}
            </div>
            <ActionableContents
              videoTitle={title}
              disableSharing={false}
              celebrity={celebrity}
              id={id}
              contentType="Shorts"
            />
          </>
        )}
      </div>
    );
  }
);

NarrativesPlayer.propTypes = {
  narrative: shape(narrativeShape).isRequired,
  className: string,
  play: bool,
  indexId: string.isRequired,
  celebrity: shape(celebrityInfoShape),
  showAds: bool,
  goToNext: func,
  goToPrevious: func,
  showPreviousArrow: bool,
  showNextArrow: bool
};

export default NarrativesPlayer;
