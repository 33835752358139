import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import AdManagerList from 'components/AdManager/AdManager';
import { defineMessages, useIntl } from 'react-intl';
import NoAds from 'components/AdManager/NoAds';
import UserService from 'services/userService';
import Loading from 'components/common/Loading';

const messages = defineMessages({
  title: { id: 'title.adManager' }
});

const AdManager = () => {
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(true);

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      setUserProfile(data?.user);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };

  const intl = useIntl();

  useEffect(() => {
    getProfile();
  }, []);

  if (loading) {
    return <div className="home-loading">
    <Loading />
  </div>;
  }

  return (
    <div className={cn('ad-manager')}>
      <div className="ad-manager-gradient" />
      <div className="layer content-container">
        <div className="ad-manager-content">
          <div className="container">
            {userProfile?.subscribedToAdTier && (
              <span className="title">{intl.formatMessage(messages.title)}</span>
            )}
            {userProfile?.subscribedToAdTier ? <AdManagerList /> : <NoAds />}
          </div>
        </div>
      </div>
    </div>
  );
};

AdManager.loadData = async () => {};

export default AdManager;
