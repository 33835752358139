import { routesPaths } from 'constants/routesPaths';
import HomePage from 'pages/HomePage';
import CelebrityIdPage from 'pages/CelebrityIdPage';
import SeriesPage from 'pages/SeriesPage';
import NarrativesPage from 'pages/NarrativesPage';
import NotFoundPage from 'pages/NotFoundPage';
import CategoryPage from 'pages/CategoryPage';
import LoginPage from 'pages/LoginPage';
import VerifyPhonePage from 'pages/VerifyPhonePage';
import SignUpPage from 'pages/SignUpPage';
import LegalPage from 'pages/LegalPage';
import LivePlayerPage from 'pages/LivePlayerPage';
import AccountPage from 'pages/AccountPage';
import ScheduledLivePlayerPage from 'pages/ScheduledLivePlayerPage';
import BecomeCreator from 'pages/BecomeCreator';
import AddNarratives from 'pages/AddNarratives';
import AddOriginals from 'pages/AddOriginals';
import AdManager from 'pages/AdManagerPage';
import AddVideoAds from 'pages/AddVideoAds';
import AddBannerAdPage from 'pages/AddBannerAdPage';
import AddWidgetAdPage from 'pages/AddWidgetAdpage';
import ManageVideoAds from 'pages/Manage-Video-Ads';
import AdDetails from 'pages/AdDetails';
import Plans from 'components/AdManager/Plans';
import SearchPage from 'pages/SearchPage';
import AddEvent from 'pages/AddEventPage';

const routes = [
  {
    path: routesPaths.home,
    component: HomePage,
    exact: true
  },
  {
    path: routesPaths.celebrityId,
    component: CelebrityIdPage
  },
  {
    path: routesPaths.series,
    component: SeriesPage,
    layout: false,
    exact: true
  },
  {
    path: routesPaths.narratives,
    component: NarrativesPage,
    layout: false,
    exact: true
  },
  {
    path: routesPaths.category,
    component: CategoryPage,
    customHeader: true,
    exact: true
  },
  {
    path: routesPaths.login,
    component: LoginPage,
    disableFooter: true,
    exact: true
  },
  {
    path: routesPaths.signup,
    component: SignUpPage,
    disableFooter: true,
    exact: true
  },
  {
    path: routesPaths.verifyPhone,
    component: VerifyPhonePage,
    disableFooter: true,
    exact: true
  },
  {
    path: routesPaths.live,
    component: LivePlayerPage,
    layout: false,
    exact: true
  },
  {
    path: routesPaths.scheduledLive,
    component: ScheduledLivePlayerPage,
    layout: false,
    exact: true
  },
  {
    path: routesPaths.legal,
    component: LegalPage,
    exact: true
  },
  {
    path: routesPaths.account,
    component: AccountPage,
    private: true,
    exact: true
  },
  {
    path: routesPaths.becomeCreator,
    component: BecomeCreator,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addNarrative,
    component: AddNarratives,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editNarrative,
    component: AddNarratives,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addOriginals,
    component: AddOriginals,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editOriginals,
    component: AddOriginals,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editEpisode,
    component: AddOriginals,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.adManager,
    component: AdManager,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addvideoAds,
    component: AddVideoAds,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.individualaddvideoAds,
    component: AddVideoAds,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.individualaddbannerAds,
    component: AddBannerAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editvideoAds,
    component: AddVideoAds,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.managevideoAdsNarrative,
    component: ManageVideoAds,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.managevideoAdsSeries,
    component: ManageVideoAds,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addBannerAd,
    component: AddBannerAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editBannerAd,
    component: AddBannerAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addWidgetAd,
    component: AddWidgetAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.individualaddwidgetAds,
    component: AddWidgetAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editWidgetAds,
    component: AddWidgetAdPage,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.adDetails,
    component: AdDetails,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.searchPage,
    component: SearchPage,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.addEvent,
    component: AddEvent,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.editEvent,
    component: AddEvent,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.plans,
    component: Plans,
    private: true,
    exact: true,
    disableFooter: true
  },
  {
    path: routesPaths.home,
    component: NotFoundPage,
    disableFooter: true
  }
];

export default routes;
