/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { shape, string, func } from 'prop-types';
import cn from 'classnames';

import PriceCrownIcon from 'components/common/PriceCrownIcon';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { episodeShape } from 'constants/propTypesShapes';

import { useCelebrity, useSession, useClickOutside } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { OptionsIcon, AdIcon } from 'components/icons';
import { checkSubscription } from 'utils/helpers';
import ClockImg from 'assets/clock.svg';
import CheckSquareImg from 'assets/check-square-broken.svg';
import EyeImg from 'assets/eye.svg';
import { EPISODE_STATES } from 'constants/constants';
import { isMobile } from 'react-device-detect';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';

const EpisodeThumbnail = ({
  episode: {
    thumbnailsUrls,
    title,
    exclusive,
    link,
    membersPrice,
    nonMembersPrice,
    playCount,
    totalSeconds,
    fullyWatchedPer,
    stage
  },
  className,
  onClick,
  isLoggedUser,
  Ads
}) => {
  const CLOSED = 0;
  const OPENED = 1;
  const {
    profile: { memberships }
  } = useProfile();
  const { user } = useSession();
  const { celebrity } = useCelebrity();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const isMember = checkSubscription(memberships, celebrity.id);
  const isPublished = stage === EPISODE_STATES.published;
  const statsVisible = isPublished && celebrity.id === user.id;
  const shouldShowPriceTag =
    (!!membersPrice && isMember && !link) ||
    (!link && !isMember && !!nonMembersPrice && !exclusive) ||
    (!link && !isMember && exclusive && !!membersPrice);
  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);

  return (
    <>
      <div className={cn('episode-thumbnail', className)}>
        <div className="sharing-options" ref={ref}>
          {optionsStatus === OPENED && (
            <>{isLoggedUser && <IconWithBackground icon={<AdIcon />} onClick={Ads} isSmall />}</>
          )}
          {isLoggedUser && (
            <IconWithBackground
              icon={<OptionsIcon />}
              onClick={() => setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED)}
              isSmall
            />
          )}
        </div>
        <div onClick={onClick}>
          <BackgroundOpacity
            grayscale={!link}
            background={thumbnailsUrls?.[0]?.src}
            opacity={0.4}
          />
        </div>
        {!link && (
          <PriceCrownIcon
            price={
              shouldShowPriceTag
                ? isMember
                  ? membersPrice
                  : nonMembersPrice || membersPrice
                : null
            }
            membership={!isMember && exclusive}
            className={
              isMobile ? 'episode-card-thumbnail-premium-mobile' : 'episode-card-thumbnail-premium'
            }
          />
        )}
        {statsVisible && (
          <div className="media-overlay">
            <div className="media-stat">
              <img className="media-icons" src={EyeImg} alt="" />
              <span className="media-fonts">{playCount}</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={ClockImg} alt="" />
              <span className="media-fonts">{totalSeconds}s</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={CheckSquareImg} alt="clock" />
              <span className="media-fonts">{fullyWatchedPer}%</span>
            </div>
          </div>
        )}
        <div style={{wordWrap: 'break-word'}} className="episode-thumbnail-title p1">{title}</div>
      </div>
    </>
  );
};

EpisodeThumbnail.propTypes = {
  episode: shape(episodeShape).isRequired,
  onClick: func.isRequired,
  className: string
};

export default EpisodeThumbnail;
