import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import ManageAds from 'components/ManageAds/ManageAds';
import { useParams } from 'react-router-dom';
import NarrativeService from 'services/narrativeService';
import seriesService from 'services/seriesService';
import parseError from 'utils/parseError';
import Loading from 'components/common/Loading';

const ManageVideoAds = () => {
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { narrativeId, celebrityId, seriesId, episodeId } = useParams();
  const getNarrativeData = async () => {
    try {
      setLoading(true);
      const { data } = await NarrativeService.getNarrativeById(narrativeId, celebrityId);
      setData(data.narrative);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };
  const getSeriesData = async () => {
    try {
      setLoading(true);
      const { data } = await seriesService.getOneSeries(seriesId);
      setData(data.series.episodes.find(ep => ep.episodeId === Number(episodeId)));
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (narrativeId != null) {
      getNarrativeData();
    }
  }, [narrativeId]);
  useEffect(() => {
    if (seriesId != null) {
      getSeriesData();
    }
  }, [seriesId]);
  return (
    <div className={cn('become-creator')}>
      <div className="become-creator-gradient" />
      {!loading ? (
        <ManageAds data={Data} />
      ) : (
        <div className="not-member-landing-loading">
          <Loading />
        </div>
      )}
    </div>
  );
};

ManageVideoAds.loadData = async () => {};

export default ManageVideoAds;
