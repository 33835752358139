/* eslint-disable no-use-before-define */
/* eslint-disable import/no-named-as-default-member */
import { LOADING } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { useForm, useModal, useSession, useTextInputProps, useToast, useValidation } from 'hooks';
import { bannerAdValidation } from 'utils/constraints';
import React, { memo, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Modal from 'components/common/Modal/Modal';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import useUploadBannerAd from 'hooks/adManager/useUploadBannerAd';
import AdService from 'services/adService';
import parseError from 'utils/parseError';
import useUpdateBannerAd from 'hooks/adManager/useUpdateBannerAd';
import { getTodayDate } from 'utils/date';
import { checkImageDimensions, handleDateKeyDown } from 'utils/helpers';
import { imageSizes } from 'constants/constants';
import UpgradeModal from 'components/upgradeModal/UpgradeModal';
import SelectContentModal from './SelectContentModal';

const messages = defineMessages({
  title: { id: 'title' },
  bannerAdTitle: { id: 'bannerAd.title' },
  actionButtonLabel: { id: 'label.actionButton' },
  actionButtonPlaceHolder: { id: 'placeholder.actionButton' },
  buttonTextLabel: { id: 'label.ButtonText' },
  buttontextPlaceHolder: { id: 'placeholder.ButtonText' },
  adDateLabel: { id: 'label.adEndDate' },
  adDatePlaceHolder: { id: 'placeholder.adEndDate' },
  selectContentLabel: { id: 'label.selectContent' },
  selectContent: { id: 'btn.selectContent' },
  desktopBannerLabel: { id: 'label.desktopBanner' },
  upload: { id: 'placeholder.upload' },
  desktopBannerExtras: { id: 'extra.desktopBanner' },
  mobileBannerLabel: { id: 'label.mobileBanner' },
  mobileBannerExtras: { id: 'extra.mobileBanner' },
  SingleShorts: { id: 'content.shorts' },
  SingleOrignals: { id: 'content.originals' }
});

const fields = {
  title: 'title',
  action_button_url: 'action_button_url',
  content_selection: 'content_selection',
  end_date: 'end_date',
  narrative_ids: 'narrative_ids',
  episode_ids: 'episode_ids',
  web_banner: 'web_banner',
  mobile_banner: 'mobile_banner'
};

const AddBannerForm = ({ bannerId, contentType, contentid }) => {
  const intl = useIntl();
  const {
    user: { id: celebrityId }
  } = useSession();
  const { toggle, isShowing } = useModal();
  const { toggle: upgradeToggle, isShowing: upgradeShowing } = useModal();
  const [bannerData, setBannerData] = useState();
  const desktopBannerRef = useRef(null);
  const mobileBannerRef = useRef(null);
  const { showErrorToast } = useToast();
  const [desktopBanner, setDesktopBanner] = useState(null);
  const [mobileBanner, setMobileBanner] = useState(null);
  const validator = useValidation(bannerAdValidation);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [ratio, setRatio] = useState();
  const checkType = useRef();
  const [labelSelectedContent, setlabelSelectedContent] = useState('');
  const [selectedContent, setSelectedContent] = useState(null);
  const [isWebBannerFalse, setIsWebBannerFalse] = useState(false);
  const [isMobileBannerFalse, setIsMobileBannerFalse] = useState(false);
  const {
    onSubmit: onCreate,
    status,
    error,
    loading: createLoading,
    isOverLimit
  } = useUploadBannerAd();
  const { onSubmit: onUpdate, loading: updateLoading } = useUpdateBannerAd();
  const [contenteDisable, setcontenteDisable] = useState(false);

  const handleUpdate = async formData => {
    const updatedFormData = {
      ...formData,
      narrative_ids: formData.narrative_ids
        ? formData.narrative_ids.map(item => item)
        : bannerData.narratives.map(item => item.id),
      episode_ids: formData.episode_ids
        ? formData.episode_ids.map(item => item)
        : bannerData.episodes.map(item => item.episodeId),
      action_button_url: values[fields.action_button_url] || '',
      web_banner: !desktopBanner ? bannerData.webBannerUrl : desktopBanner,
      mobile_banner: !mobileBanner ? bannerData.mobileBannerUrl : mobileBanner
    };
    return onUpdate({ ...updatedFormData, id: bannerId });
  };

  const handleCreate = async formData => {
    const updatedFormData = {
      ...formData,
      narrative_ids: formData.narrative_ids || [],
      episode_ids: formData.episode_ids || []
    };
    return onCreate(updatedFormData);
  };

  const handleFormSubmit = formData => {
    return bannerId ? handleUpdate(formData) : handleCreate(formData);
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      validator,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
      validateOnChange: true
    },
    [handleFormSubmit]
  );
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const handleDesktopBannerUpload = () => {
    desktopBannerRef.current.click();
  };

  const handleMobileBannerUpload = () => {
    mobileBannerRef.current.click();
  };

  const handleDesktopBannerChange = async e => {
    const file = e.target.files[0];
    const validationResult = await checkImageDimensions(
      file,
      imageSizes.bannerAdDesktop.width,
      imageSizes.bannerAdDesktop.height
    );
    if (validationResult.isValid) {
      setCropModalOpen(true);
      setCroppingUrl(URL.createObjectURL(file));
      setRatio(16 / 1);
      checkType.current = 'desktop';
    } else {
      showErrorToast(validationResult.error);
      e.target.value = null;
    }
  };

  const handleMobileBannerChange = async e => {
    const file = e.target.files[0];
    const validationResult = await checkImageDimensions(
      file,
      imageSizes.bannerAdMobile.width,
      imageSizes.bannerAdMobile.height
    );
    if (validationResult.isValid) {
      setCropModalOpen(true);
      setRatio(2 / 1);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'mobile';
    } else {
      showErrorToast(validationResult.error);
      e.target.value = null;
    }
  };

  const handleEndDateChange = e => {
    const endDate = e.target.value;
    handleValueChange(fields.end_date, endDate);
  };

  const handleSaveContent = content => {
    setSelectedContent(content);
    setValues(prevValues => ({
      ...prevValues,
      [fields.narrative_ids]: content.narratives,
      [fields.episode_ids]: content.series
    }));
    toggle();
    setlabelSelectedContent(
      `${content.series.length} MOVIES & SERIES, ${content.narratives.length} SHORTS SELECTED`
    );
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setRatio();
  };

  const handleCrop = cropImg => {
    if (checkType.current === 'desktop') {
      handleValueChange(fields.web_banner, cropImg);
      setDesktopBanner(cropImg);
    } else {
      handleValueChange(fields.mobile_banner, cropImg);
      setMobileBanner(cropImg);
    }
  };

  const getBannerData = async () => {
    try {
      const { data } = await AdService.getBannerById(bannerId, celebrityId);
      setBannerData(data.bannerAd);
      const episodeIds = data.bannerAd.episodes.map(item => item.id);
      const narrativeIds = data.bannerAd.narratives.map(item => item.id);
      setSelectedContent({
        narratives: narrativeIds,
        series: episodeIds
      });
      setlabelSelectedContent(
        `${episodeIds.length} MOVIES & SERIES, ${narrativeIds.length} SHORTS SELECTED`
      );
      setDesktopBanner(data.bannerAd.webBannerUrl);
      setMobileBanner(data.bannerAd.mobileBannerUrl);
      setValues({
        ...data.bannerAd,
        [fields.action_button_url]: data.bannerAd.actionButtonUrl,
        [fields.end_date]: data.bannerAd.endDate
          ? new Date(data.bannerAd.endDate).toISOString().split('T')[0]
          : null
      });
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    if (bannerId) {
      getBannerData();
    }
  }, [bannerId]);
  const handleCustom = () => {
    if (contentType === 'narrative') {
      setSelectedContent({
        narratives: [contentid],
        series: []
      });
      setlabelSelectedContent(messages.SingleShorts.id);
      setValues(prevValues => {
        const newValues = { ...prevValues };
        newValues[fields.narrative_ids] = [contentid];
        return newValues;
      });
      setcontenteDisable(true);
    } else if (contentType === 'seriesid') {
      setSelectedContent({
        narratives: [],
        series: [contentid]
      });
      setlabelSelectedContent(messages.SingleOrignals.id);
      setValues(prevValues => {
        const newValues = { ...prevValues };
        newValues[fields.episode_ids] = [contentid];
        return newValues;
      });
      setcontenteDisable(true);
    }
  };
  useEffect(() => {
    handleCustom();
  }, [contentType, contentid]);

  useEffect(() => {
    if (isOverLimit) {
      upgradeToggle();
    }
  }, [isOverLimit]);
  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.bannerAdTitle)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="action_button_url"
          placeholder={intl.formatMessage(messages.actionButtonPlaceHolder)}
          label={intl.formatMessage(messages.actionButtonLabel)}
          errors={errors[fields.action_button_url]}
          {...inputProps(fields.action_button_url)}
        />
        <div className="date-card">
          <Input
            name="end_date"
            type="date"
            placeholder={intl.formatMessage(messages.adDatePlaceHolder)}
            label={intl.formatMessage(messages.adDateLabel)}
            onChange={handleEndDateChange}
            onKeyDown={handleDateKeyDown}
            value={values[fields.end_date]}
            min={getTodayDate()}
          />
        </div>
        <input
          type="file"
          ref={desktopBannerRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="web_banner"
          onChange={handleDesktopBannerChange}
        />
        <input
          type="file"
          ref={mobileBannerRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleMobileBannerChange}
          name="mobile_banner"
        />
        <div className="upload-div">
          <span className={desktopBanner?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.desktopBannerLabel)}
          </span>
          {bannerId && desktopBanner && !isWebBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={bannerData?.webBannerUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setDesktopBanner(null);
                  setIsWebBannerFalse(true);
                  handleDesktopBannerUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {desktopBanner && (!bannerId || isWebBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{desktopBanner?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopBanner(null)}
              >
                x
              </span>
            </div>
          )}
          {!desktopBanner && (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.upload)}
                type="secondary"
                onClick={handleDesktopBannerUpload}
                className="profile-button"
              />
              <span className="label-margin">
                {intl.formatMessage(messages.desktopBannerExtras)}
              </span>
            </div>
          )}
        </div>
        <div className="upload-div">
          <span className={mobileBanner?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.mobileBannerLabel)}
          </span>
          {bannerId && mobileBanner && !isMobileBannerFalse && (
            <div className="display-loaded-pic">
              <img
                className="mobile-url-pic"
                src={bannerData?.mobileBannerUrl}
                alt="mobile-banner"
              />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setMobileBanner(null);
                  setIsMobileBannerFalse(true);
                  handleMobileBannerUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {mobileBanner && (!bannerId || isMobileBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{mobileBanner?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setMobileBanner(null)}
              >
                x
              </span>
            </div>
          )}
          {!mobileBanner && (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.upload)}
                type="secondary"
                onClick={handleMobileBannerUpload}
                className="profile-button"
              />
              <span className="label-margin">
                {intl.formatMessage(messages.mobileBannerExtras)}
              </span>
            </div>
          )}
        </div>
        <div className="button-margin">
          <Button
            labelId={labelSelectedContent || messages.selectContent.id}
            type="secondary"
            onClick={toggle}
            className="profile-button"
            disabled={contenteDisable}
          />
        </div>
      </div>
      {!isOverLimit && error && <div className="creator-form-error p1">{error}</div>}
      <Button
        labelId={bannerId ? 'save Changes' : 'banner.btnName'}
        className="submit-button"
        onClick={handleSubmit}
        disabled={
          status === LOADING ||
          !values[fields.title] ||
          !desktopBanner ||
          !mobileBanner ||
          selectedContent === null ||
          createLoading ||
          updateLoading
        }
      />
      {(status === LOADING || createLoading || updateLoading) && !error && (
        <Loading type="ball-clip-rotate" />
      )}
      <Modal hide={toggle} isShowing={isShowing} className="series-form-modal">
        <SelectContentModal
          content={selectedContent}
          handleClose={toggle}
          onSaveContent={handleSaveContent}
        />
      </Modal>
      {/* {isOverLimit && ( */}
      <Modal hide={upgradeToggle} isShowing={upgradeShowing} className="series-form-modal">
        <UpgradeModal />
      </Modal>
      {/* )} */}
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={ratio}
        />
      )}
    </div>
  );
};

export default memo(AddBannerForm);
