import { LOADING } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { ALLOWED_TIME_ZONES, imageSizes } from 'constants/constants';
import { useForm, useNavigation, useTextInputProps, useToast, useValidation } from 'hooks';
import { eventValidation } from 'utils/constraints';
import React, { memo, useEffect, useRef, useState } from 'react';
import { checkImageDimensions, handleDateKeyDown } from 'utils/helpers';
import Select from 'react-select';
import cn from 'classnames';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import { customDropDownStyles } from 'styles/customDropDownStyles';
import { getTodayDate } from 'utils/date';
import useCreateEvent from 'hooks/event/useCreateEvent';
import { some } from 'lodash';
import { routesPaths } from 'constants/routesPaths';
import EventService from 'services/eventService';
import parseError from 'utils/parseError';
import useUpdateEvent from 'hooks/event/useUpdateEvent';

const fields = {
  title: 'title',
  description: 'description',
  event_date: 'event_date',
  duration: 'duration',
  timezone: 'timezone',
  event_time: 'event_time',
  members_price: 'members_price',
  non_members_price: 'non_members_price',
  web_cover_image: 'web_cover_image',
  mobile_cover_image: 'mobile_cover_image',
  record_event: 'record_event',
  notify_subscribers: 'notify_subscribers'
};

const AddEventForm = ({ eventId, celebrityId }) => {
  const { goTo } = useNavigation();
  const desktopCoverRef = useRef(null);
  const mobileCoverRef = useRef(null);
  const [desktopCoverPic, setDesktopCoverPic] = useState(null);
  const [mobileCoverPic, setMobileCoverPic] = useState(null);
  const { onSubmit: handleCreate, status, error } = useCreateEvent();
  const { onSubmit: handleUpdate, status: updateStatus, error: updateError } = useUpdateEvent();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [aspectRatio, setAspectRatio] = useState();
  const checkType = useRef();
  const { showErrorToast } = useToast();
  const validator = useValidation(eventValidation);
  const [subscribedFans, setSubScribedFans] = useState(true);
  const [recordEvent, setRecordEvent] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [duration, setDuration] = useState('');
  const [membersPrice, setMembersPrice] = useState('');
  const [nonMembersPrice, setNonMembersPrice] = useState('');
  const [eventData, setEventData] = useState();
  const [isDesktopBannerFalse, setIsDesktopBannerFalse] = useState(false);
  const [isMobileBannerFalse, setIsMobileBannerFalse] = useState(false);

  const timezoneOptions = ALLOWED_TIME_ZONES.map(zone => ({
    value: zone,
    label: zone
  }));
  const handleFormSubmit = async data => {
    if (eventId) {
      handleUpdate(celebrityId, eventId, data);
    } else {
      handleCreate(data);
    }
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit: handleFormSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        [fields.notify_subscribers]: true,
        [fields.record_event]: true
      }
    },
    [handleFormSubmit]
  );
  const handleTimezoneChange = selectedOption => {
    setSelectedTimezone(selectedOption);
    handleValueChange(fields.timezone, selectedOption.value);
  };

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const handleDesktopCoverUpload = () => {
    desktopCoverRef.current.click();
  };
  const handleMobileCoverUpload = () => {
    mobileCoverRef.current.click();
  };

  const handleDesktopCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.eventDesktopCover.width,
      imageSizes.eventDesktopCover.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'desktop';
    } else {
      showErrorToast(result.error);
    }
  };

  const handleMobileCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.eventMobileCover.width,
      imageSizes.eventMobileCover.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'mobile';
    } else {
      showErrorToast(result.error);
    }
  };

  const onChangeMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.members_price, valueWithoutDollar);
      setMembersPrice(value);
    } else {
      setMembersPrice('');
      handleValueChange(fields.members_price, '');
    }
  };

  const onChangeNonMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.non_members_price, valueWithoutDollar);
      setNonMembersPrice(value);
    } else {
      setNonMembersPrice('');
      handleValueChange(fields.non_members_price, '');
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio();
    checkType.current = null;
  };

  const handleCrop = cropImg => {
    if (checkType.current === 'desktop') {
      setDesktopCoverPic(cropImg);
      handleValueChange(fields.web_cover_image, cropImg);
    } else {
      setMobileCoverPic(cropImg);
      handleValueChange(fields.mobile_cover_image, cropImg);
    }
  };

  const onChangeDuration = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}:${value.slice(5, 7)}`;
    }
    handleValueChange(fields.duration, value);
    setDuration(value);
  };

  useEffect(() => {
    if (eventData) {
      setSelectedTimezone({ label: eventData.timezone, value: eventData.timezone });
      setDuration(eventData.duration);
      setMembersPrice(`$${eventData.membersPrice}`);
      setNonMembersPrice(`$${eventData.nonMembersPrice}`);
      setMobileCoverPic(eventData.mobileCoverImageUrl);
      setDesktopCoverPic(eventData.webCoverImageUrl);
      setValues({
        ...eventData,
        [fields.event_date]: eventData.eventDate
          ? new Date(eventData.eventDate).toISOString().split('T')[0]
          : null,
        [fields.event_time]: eventData.eventTime
          ? new Date(eventData.eventTime).toLocaleTimeString('en-US', {
              hour12: false,
              hour: '2-digit',
              minute: '2-digit'
            })
          : null,
        [fields.members_price]: eventData.membersPrice,
        [fields.non_members_price]: eventData.nonMembersPrice
      });
    }
  }, [eventData]);

  const getEventData = async () => {
    try {
      const { data } = await EventService.getEventByEventId(eventId, celebrityId);
      setEventData(data.event);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    if (eventId) {
      getEventData();
    }
  }, [eventId]);

  return (
    <div className="add-event-form">
      <div className="add-event-form-fields">
        <Input
          name="title"
          placeholder="Event Title"
          label="Title"
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder="Event Description"
          label="Description"
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <div className={cn('custom-short-content')}>
          <span className="custom-short-content-label">Time Zone</span>
          <div className="single-select-short">
            <Select
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              styles={customDropDownStyles}
              options={timezoneOptions}
              isSearchable
            />
          </div>
        </div>
        <Input
          name="duration"
          placeholder="HH:MM:SS"
          label="Duration"
          errors={errors[fields.duration]}
          {...inputProps(fields.duration)}
          maxLength={8}
          value={duration}
          onChange={onChangeDuration}
        />

        <div className="input-container">
          <div className="input-card">
            <div className="d-flex">
              <Input
                name="date"
                type="date"
                placeholder="DD/MM/YYYY"
                label="Date"
                value={values[fields.event_date]}
                onKeyDown={handleDateKeyDown}
                onChange={e => handleValueChange(fields.event_date, e.target.value)}
                min={getTodayDate()}
              />
              <Input
                name="time"
                type="time"
                placeholder="HH:MM:SS"
                label="Time"
                value={values[fields.event_time]}
                onKeyDown={handleDateKeyDown}
                onChange={e => handleValueChange(fields.event_time, e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="d-flex">
            <Input
              name="memberPrice"
              placeholder="$0"
              label="Member price"
              errors={errors[fields.members_price]}
              {...inputProps(fields.members_price)}
              onChange={onChangeMembersPrice}
              value={membersPrice}
            />
            <Input
              name="nonMemberPrice"
              placeholder="$0"
              label="non-member price"
              errors={errors[fields.non_members_price]}
              {...inputProps(fields.non_members_price)}
              onChange={onChangeNonMembersPrice}
              value={nonMembersPrice}
            />
          </div>
        </div>
        <input
          type="file"
          ref={desktopCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="profile_image"
          onChange={handleDesktopCoverChange}
        />
        <input
          type="file"
          ref={mobileCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleMobileCoverChange}
          name="banner"
        />
        <div>
          <span className={desktopCoverPic?.name ? 'white-custom-label' : 'custom-label'}>
            Cover Image (Desktop)
          </span>
          {eventId && desktopCoverPic && !isDesktopBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={eventData?.webCoverImageUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setDesktopCoverPic(null);
                  setIsDesktopBannerFalse(true);
                  handleDesktopCoverUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {desktopCoverPic && (!eventId || isDesktopBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{desktopCoverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopCoverPic(null)}
              >
                x
              </span>
            </div>
          )}
          {!desktopCoverPic && (
            <div className="button-margin">
              <Button
                title="UPLOAD COVER IMAGE"
                type="secondary"
                onClick={handleDesktopCoverUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 800px X 450px (16:9)</span>
            </div>
          )}
        </div>
        <div>
          <span className={mobileCoverPic?.name ? 'white-custom-label' : 'custom-label'}>
            cover image (mobile)
          </span>
          {eventId && mobileCoverPic && !isMobileBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={eventData?.mobileCoverImageUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setMobileCoverPic(null);
                  setIsMobileBannerFalse(true);
                  handleMobileCoverUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {mobileCoverPic && (!eventId || isMobileBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{mobileCoverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setMobileCoverPic(null)}
              >
                x
              </span>
            </div>
          )}
          {!mobileCoverPic && (
            <div className="button-margin">
              <Button
                title="UPLOAD COVER IMAGE"
                type="secondary"
                onClick={handleMobileCoverUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 320px X 180px (16:9)</span>
            </div>
          )}
        </div>
        {!eventId && (
          <div className={cn('notify-box')}>
            <input
              type="checkbox"
              checked={recordEvent}
              onChange={() => {
                setRecordEvent(!recordEvent);
                handleValueChange(fields.record_event, !recordEvent);
              }}
            />
            Record/Save event
          </div>
        )}
        {!eventId && (
          <div className={cn('notify-box')}>
            <input
              type="checkbox"
              checked={subscribedFans}
              onChange={() => {
                setSubScribedFans(!subscribedFans);
                handleValueChange(fields.notify_subscribers, !subscribedFans);
              }}
            />
            Notify all paid fans
          </div>
        )}
      </div>
      <div className="creator-form-error p1">{error || updateError}</div>
      <div className="btn-container">
        <Button
          title="Cancel"
          className="profile-button"
          type="secondary"
          onClick={() => goTo(routesPaths.home)}
        />
        <Button
          title={eventId ? 'Save Changes' : 'Schedule event'}
          className="submit-button"
          onClick={handleSubmit}
          type="submit"
          disabled={
            status === LOADING ||
            updateStatus === LOADING ||
            some(errors) ||
            !values[fields.title] ||
            !values[fields.description] ||
            !values[fields.members_price] ||
            !values[fields.non_members_price] ||
            !desktopCoverPic ||
            !mobileCoverPic
          }
        />
      </div>
      {status === LOADING && <Loading type="ball-clip-rotate" />}
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default memo(AddEventForm);
