import { getAdPlacement } from 'constants/constants';
import React from 'react';

const AllDetails = ({ type, data }) => {
  return (
    <>
      <div className="title-container">
        <div className="main-title">{data?.title}</div>
        {type !== 'bannerAds' && (
          <div className="time-stamp">
            <span>{data?.videoLength ? `00:00:${data?.videoLength}` : '-'}</span>
          </div>
        )}
      </div>
      <div className="other-info">
        <div className="date">
          <div>
            <span className="light-title">End Date</span>
            <span className="light-text">
              {data?.endDate ? new Date(data.endDate).toLocaleDateString() : '-'}
            </span>
          </div>
        </div>
        <div className="impressions">
          <div>
            <span className="light-title">Impressions</span>
            <span className="light-text">{data?.impressions || '-'}</span>
          </div>
        </div>
        <div className="clicks">
          <div>
            <span className="light-title">Clicks</span>
            <span className="light-text">{data?.clicks || '-'}</span>
          </div>
        </div>
        <div>
          <div>
            <span className="light-title">Active in</span>
            <span className="light-text">
              {' '}
              {data?.narrativeCounts > 0 &&
                `${data?.narrativeCounts} Shorts${`${data?.episodeCounts > 0 ? ', ' : ''}`}`}
              {data?.episodeCounts > 0 &&
                `${data?.episodeCounts} Movies & Series${data?.episodeCounts !== 1 ? 's' : ''}`}
              {data?.episodeCounts === 0 && data?.narrativeCounts === 0 && '-'}
            </span>
          </div>
        </div>
        {type === 'videoAds' && (
          <div className="ad-placement">
            <div>
              <span className="light-title">Ad Placement</span>
              <span className="light-text">{getAdPlacement(data?.placementOption)}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllDetails;
