/* eslint-disable indent */
import React, { memo, cloneElement, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { node, string, func, bool, number, oneOfType } from 'prop-types';
import cn from 'classnames';

import { useDisableScroll } from 'hooks';

import { zIndexModal, zIndexNegative } from 'styles/common/_constants.scss';

const Modal = ({ children, className, isShowing, hide, leftAnimation, zIndex, disablePadding }) => {
  const [showContent, setShowContent] = useState(isShowing);

  const timeOut = useRef(null);

  // ShowContent is used for smooth ease out animations
  useEffect(() => {
    if (!isShowing) {
      timeOut.current = setTimeout(() => {
        setShowContent(isShowing);
      }, 700);
    } else {
      clearTimeout(timeOut.current);
      setShowContent(isShowing);
    }
  }, [isShowing]);

  useDisableScroll(isShowing);

  return window?.document
    ? ReactDOM.createPortal(
        <div
          className={cn(
            'modal',
            className,
            { viewable: isShowing },
            { 'left-animation': leftAnimation }
          )}
          style={{ zIndex: isShowing ? zIndex : zIndexNegative }}
        >
          {showContent && (
            <div className={cn('modal-content', { 'disable-padding': disablePadding })}>
              {cloneElement(children, { hide: isShowing ? hide : () => {} })}
            </div>
          )}
        </div>,
        document.getElementById('app')
      )
    : null;
};

Modal.propTypes = {
  children: node.isRequired,
  className: string,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  leftAnimation: bool,
  zIndex: oneOfType([number, string]),
  disablePadding: bool
};

Modal.defaultProps = {
  zIndex: zIndexModal
};

export default memo(Modal);
