/* eslint-disable no-useless-escape */
import validate from 'validate.js';

validate.validators.presence.options = { allowEmpty: false };

validate.validators.digitsOnly = (value, options, key) => {
  if (value && !/^\d+$/.test(value)) {
    return options.message || `${key} must contain only digits`;
  }
};

export const validations = (constraints, props = {}) => data =>
  validate(data, constraints, props) || {};

export const login = {
  phone: {
    presence: true
  }
};

export const signup = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  email: {
    presence: true,
    email: true
  },
  phone: {
    presence: true
  }
};

export const billing = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  zipcode: {
    presence: true
  },
  country: {
    presence: true
  }
};

export const creator = {
  username: {
    presence: true
  },
  membershipPrice: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 1,
      lessThan: 1000000
    }
  }
};

export const creatorValidations = {
  membershipPrice: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 1,
      lessThan: 1000000
    }
  }
};

export const bannerAdValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  action_button_url: {
    format: {
      pattern: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/i,
      message: 'is invalid'
    }
  }
};

export const widgetAdValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  }
};

export const createNarrativeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  leadCast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  }
};

export const createSeriesValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  lead_cast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  }
};

export const episodeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  lead_cast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  }
  // creditsStartTime: {
  //   format: {
  //     pattern: /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
  //     message: 'must have the format HH:MM:SS'
  //   }
  // }
};

export const videoadsValidations = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  videoLength: {
    presence: true
  },
  adPlacement: {
    presence: true
  },
  externalLink: {
    presence: false,
    format: {
      pattern: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&%=._-]*)*\/?$/,
      message: 'is not valid'
    }
  },
  // buttonText: {
  //   presence: false,
  //   length: {
  //     maximum: 16
  //   }
  // },
  adEndDate: {
    presence: false
  }
};

export const eventValidation = {
  title: {
    presence: true,
    length: {
      maximum: 100
    }
  },
  description: {
    presence: true,
    length: {
      maximum: 160
    }
  },
  event_date: {
    presence: true
  },
  duration: {
    presence: true,
    format: {
      pattern: /^(\d{1,2}):([0-5]?\d|60):([0-5]?\d|60)$/,
      message: '^Please enter a valid duration in HH:MM:SS format.'
    }
  }
};
