import React from 'react';
import { useIntl } from 'react-intl';
import { shape, func, bool, oneOfType } from 'prop-types';
import { isMobile } from 'react-device-detect';

import { episodeShape, celebrityShape } from 'constants/propTypesShapes';
import { getFullName } from 'utils/helpers';

import { CrossIcon, PlayIcon } from 'components/icons';
import Button from 'components/common/Button/Button';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';

import { white } from 'styles/common/_constants.scss';

const NextEpisodeBanner = ({
  hasNext,
  episode,
  celebrity: { firstName, lastName },
  onClose,
  onClick
}) => {
  const intl = useIntl();
  const { thumbnailsUrls = [], title } = episode;

  return hasNext ? (
    <div className="next-episode-banner">
      <div className="next-episode-banner-thumbnail">
        <BackgroundOpacity
          background={thumbnailsUrls && thumbnailsUrls.length ? thumbnailsUrls[0]?.src : ''}
        />
        <IconWithBackground
          icon={<CrossIcon color={white} />}
          onClick={onClose}
          className="close-icon clickable"
          isSmall
        />
      </div>
      <h6>{intl.formatMessage({ id: 'episodes.nextEpisode' })}</h6>
      <h5 className="bold">{title}</h5>
      <Button
        labelId="episodes.playEpisode"
        onClick={onClick}
        type="primary"
        size={isMobile ? 'xxsmall' : 'small'}
        icon={<PlayIcon />}
      />
    </div>
  ) : (
    <Button
      title={intl.formatMessage(
        { id: 'episodes.goToId' },
        { name: getFullName(firstName, lastName) }
      )}
      onClick={onClick}
      type="primary"
      size={isMobile ? 'xxsmall' : 'small'}
      className={isMobile ? 'last-episode-banner-mobile' : 'last-episode-banner'}
    />
  );
};

NextEpisodeBanner.propTypes = {
  episode: oneOfType([shape(episodeShape).isRequired, bool]),
  celebrity: shape(celebrityShape),
  onClose: func.isRequired,
  hasNext: bool,
  onClick: func.isRequired
};

export default NextEpisodeBanner;
