import React from 'react';
import cn from 'classnames';
import { useNavigation, useSession } from 'hooks';
import { BackArrowIcon } from 'components/icons';
import { useParams } from 'react-router-dom';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { convertMinutesToHHMMSS } from 'utils/date';
import DefalutImg from '../../assets/dummyVideoAds.png';
import RightSide from './RightSide';

const ManageAds = ({ data }) => {
  const { goTo } = useNavigation();
  const { narrativeId } = useParams();
  const { user } = useSession();
  return (
    <div className={cn('ad-manager-indivual')}>
      <div className="banner">
        <div
          className="back-button"
          style={{ display: 'flex', marginTop: '15px' }}
          onClick={() => {
            goTo(
              routeWithProps(routesPaths.celebrityId, {
                username: user.username
              })
            );
          }}
        >
          <BackArrowIcon />
        </div>
        <div className="page-title">
          {!narrativeId ? (
            <span style={{ fontSize: '24px' }}>Manage ads in your Movies & Series</span>
          ) : (
            <span style={{ fontSize: '24px' }}>Manage ads in your Shorts</span>
          )}
        </div>
        <div className="banner-content">
          <div className="left-content">
            <div className="container">
              <img
                src={data?.thumbnailsUrls?.[0].src || DefalutImg}
                className="video-thumbnail"
                alt="videothumnail"
              />
              <div className="time-details">
                <h4>{data?.title}</h4>
                <span>{convertMinutesToHHMMSS(data?.durationMinutes)}</span>
              </div>
            </div>
          </div>
          <div className="right-content">
            <h2 style={{ display: 'flex', marginTop: '15px' }}>Active Ads</h2>
            <RightSide data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageAds;
