/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useStatus, LOADING, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import { MIXPANEL_EVENTS, PURCHASE_TYPES } from 'constants/constants';

import useEpisode from 'hooks/useEpisode';
import useProfile from 'hooks/users/useProfile';
import useMembership from 'hooks/celebrities/useMembership';
import { useAnalytics, useScheduleLive, useToast } from 'hooks';

import { getFullName } from 'utils/helpers';

import {
  buyEpisode,
  createMembership,
  subscribeAndBuyEpisode
} from 'state/actions/membershipActions';
import { purchaseScheduledLiveEvent } from 'state/actions/liveEventsActions';
import { getCelebrity } from 'state/actions/celebritiesActions';

export default (celebrity, liveEvent, onSuccess, hide, purchaseType) => {
  const intl = useIntl();
  const { newMembership, membershipPlans } = useMembership();
  const { purchaseScheduledLive } = useScheduleLive();
  const membership = membershipPlans[0];
  const { showToast, showErrorToast } = useToast();
  const { firstName, lastName } = celebrity || {};
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { requestProfile } = useProfile();
  const { episode } = useEpisode();

  const { status: buyEpisodeStatus, error: buyEpisodeError } = useStatus(buyEpisode);

  const { status: subscribeAndBuyEpisodeStatus, error: subscribeAndBuyEpisodeError } = useStatus(
    subscribeAndBuyEpisode
  );

  const { status: createMembershipStatus, error: createMembershipError } = useStatus(
    createMembership
  );

  const { status: purchaseScheduledStatus, error: purchaseScheduledError } = useStatus(
    purchaseScheduledLiveEvent
  );

  const onConfirm = creditCard => {
    switch (true) {
      case !!celebrity && !purchaseType:
        newMembership(celebrity.id, membership.id, creditCard);
        break;
      case !!liveEvent && !purchaseType:
        purchaseScheduledLive(creditCard, liveEvent.id);
        break;
      case purchaseType === PURCHASE_TYPES.EPISODE:
        dispatch(buyEpisode({ episodeId: episode.episodeId, creditCardId: creditCard }));
        break;
      case purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE:
        dispatch(
          subscribeAndBuyEpisode(celebrity.id, membership.id, creditCard, episode.episodeId)
        );
        break;
      default:
    }
  };

  useEffect(() => {
    if (createMembershipStatus === SUCCESS) {
      requestProfile();
      dispatch(createMembership.reset());
      trackEvent(MIXPANEL_EVENTS.subscriptionConfirmed, {
        'Subscription Type': membership.name,
        'Creator Name': getFullName(firstName, lastName)
      });
      !!onSuccess && onSuccess();
      showToast(intl.formatMessage({ id: 'membership.create.success' }));
      hide();
    }
    if (createMembershipStatus === ERROR) {
      showErrorToast(createMembershipError);
    }
  }, [createMembershipStatus]);

  useEffect(() => {
    if (buyEpisodeStatus === SUCCESS) {
      dispatch(buyEpisode.reset());
      dispatch(getCelebrity(celebrity.id));
      trackEvent(MIXPANEL_EVENTS.episodeBought);
      !!onSuccess && onSuccess();
      showToast(intl.formatMessage({ id: 'unlock.episode.success' }));
    } else if (buyEpisodeStatus === ERROR) {
      showErrorToast(buyEpisodeError);
      dispatch(buyEpisode.reset());
    }
  }, [buyEpisodeStatus]);

  useEffect(() => {
    if (subscribeAndBuyEpisodeStatus === SUCCESS) {
      dispatch(subscribeAndBuyEpisode.reset());
      dispatch(getCelebrity(celebrity.id));
      trackEvent(MIXPANEL_EVENTS.subscriptionConfirmed);
      trackEvent(MIXPANEL_EVENTS.episodeBought);
      !!onSuccess && onSuccess();
      showToast(intl.formatMessage({ id: 'unlock.membership.and.episode.success' }));
    } else if (subscribeAndBuyEpisodeStatus === ERROR) {
      showErrorToast(subscribeAndBuyEpisodeError);
      dispatch(subscribeAndBuyEpisode.reset());
    }
  }, [subscribeAndBuyEpisodeStatus]);

  useEffect(() => {
    if (purchaseScheduledStatus === SUCCESS) {
      trackEvent(MIXPANEL_EVENTS.confirmPPVLivePurchase, {
        'Event Name': liveEvent.title
      });
      !!onSuccess && onSuccess();
      showToast(intl.formatMessage({ id: 'payPerView.success' }));
      hide();
    }
    if (purchaseScheduledStatus === ERROR) {
      showErrorToast(purchaseScheduledError);
    }
  }, [purchaseScheduledStatus]);

  return {
    onConfirm,
    loading:
      createMembershipStatus === LOADING ||
      purchaseScheduledStatus === LOADING ||
      buyEpisodeStatus === LOADING ||
      subscribeAndBuyEpisodeStatus === LOADING
  };
};
