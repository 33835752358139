import React, { useState } from 'react';
import { string, bool, func, oneOfType, shape, arrayOf } from 'prop-types';
import cn from 'classnames';

import { useNavigation } from 'hooks';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import { BackArrowIcon, ShareIcon, CrossIcon } from 'components/icons';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import CelebritiesButton from 'components/Players/Controls/CelebritiesButton';

import { white } from 'styles/common/_constants.scss';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { routeTypes } from 'constants/constants';

const PlayerNavigationBar = ({
  celebrities,
  videoTitle,
  disableSharing,
  onExitPlayer,
  customGoBack,
  shareText,
  title,
  description,
  closeIcon
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const { goBack, goToWithSearch } = useNavigation();

  const toggleButtons = e => {
    e.stopPropagation();
    setShowIcons(!showIcons);
  };

  const checkIsNarrative = () => {
    if (window.location.pathname.includes(routeTypes.NARRATIVE)) {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: celebrities.username
      });
      return goToWithSearch(route, `?tab=2`);
    }
    return goBack();
  };

  const onBackClicked = () => {
    !!onExitPlayer && onExitPlayer();
    customGoBack ? customGoBack() : checkIsNarrative();
  };

  return (
    <div className="navigation-bar">
      <div className="first-row">
        <div className={cn('back-button', { wide: disableSharing })} onClick={onBackClicked}>
          {closeIcon ? <CrossIcon color={white} /> : <BackArrowIcon />}
        </div>
        {!showIcons && !!title && (
          <div className="flex-center media-title">
            <h4 className="semibold">{title}</h4>
          </div>
        )}

        <div className={cn('right-content', { 'celebrity-padded': disableSharing })}>
          {!disableSharing && !!celebrities && (
            // <div className="secondary-row">
            <CelebritiesButton celebrities={celebrities} onExitPlayer={onExitPlayer} />
            // </div>
          )}
        </div>
      </div>

      {/* {disableSharing ? (
        !!celebrities && (
          <CelebritiesButton celebrities={celebrities} onExitPlayer={onExitPlayer} topRow />
        )
      ) : (
        <div className="top-row">
          {showIcons ? (
            <SharingBlock
              onClose={toggleButtons}
              shareUrl={window.location.href}
              videoTitle={videoTitle}
              shareText={shareText}
            />
          ) : (
            <div className="share-button" onClick={toggleButtons}>
              <ShareIcon strokeWidth={2} />
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};

PlayerNavigationBar.propTypes = {
  videoTitle: string,
  disableSharing: bool,
  shareText: string,
  onExitPlayer: func,
  customGoBack: func,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  title: string,
  description: string,
  closeIcon: bool
};

export default PlayerNavigationBar;
