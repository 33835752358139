import React from 'react';
import cn from 'classnames';
import { useClickOutside, useNavigation } from 'hooks';
import Button from 'components/common/Button/Button';
import { func } from 'prop-types';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import IdFrameImg from 'assets/IDFrame.png';

const UpgradeModal = ({ hide, type }) => {
  const ref = useClickOutside(hide);

  const { goTo } = useNavigation();

  return (
    <div className={cn('upgrade-modal')} ref={ref}>
      <div className="layer upgrade-modal-header-content">
        <div className="content-div">
          <div className="content-container">
            <img width="105" height="30" src={IdFrameImg} alt="idFrameLogo" />
            <span>Upgrade to access</span>
            <span className="para">
              {`You've reached your ad upload limit for ${type}. Upgrade your plan to increase your limits and keep uploading more ads.`}
            </span>
          </div>

          <div className="buttons">
            <Button
              type="primary"
              labelId="Upgrade"
              className="upgrade-btn"
              onClick={() => goTo(routeWithProps(routesPaths.account, { tab: 'plans' }))}
            />
            <Button className="cancel-btn" onClick={hide}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

UpgradeModal.propTypes = {
  hide: func.isRequired
};

export default UpgradeModal;
