import { createReducer } from '@rootstrap/redux-tools';
import { deleteReaction, getReaction, reaction } from 'state/actions/reactionAction';

const initialState = {
  reactionResponse: {}
};

export default createReducer(initialState, {
  [reaction.success]: (state, { payload }) => {
    state.reactionResponse = payload?.narrative ?? payload?.episode ?? {};
  },
  [getReaction.success]: (state, { payload }) => {
    state.reactionResponse = payload ?? {};
  },
  [deleteReaction.success]: (state, { payload }) => {
    state.reactionResponse = payload?.narrative ?? payload?.episode ?? {};
  }
});
