/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { shape, string, number, oneOfType } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { ImageSlider } from 'components/common/slider/imageSlider';
import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { useAnalytics, useIsMobile, useSession } from 'hooks';
import { routeWithProps, checkSubscription } from 'utils/helpers';
import useProfile from 'hooks/users/useProfile';
import useDisappearingControls from 'hooks/useDisappearingControls';
import SeriesAdditionalControls from 'components/Players/Controls/SeriesAdditionalControls';
import useNavigation from 'hooks/useNavigation';
import ActionableContents from 'components/actionableContents/ActionableContents';
import useVideoPlayer from './useVideoPlayer';

const SeriesPlayer = ({ series, episodeIndex }) => {
  const history = useHistory();
  const { replace } = history;
  const { trackEvent } = useAnalytics();
  const { goToWithSearch } = useNavigation();
  const containerRef = useRef();
  const { handleMouseMove, controlsVisible, handleClick } = useDisappearingControls(true);
  const { search, state } = useLocation();

  const {
    profile: { memberships }
  } = useProfile();
  const { id, episodes, title, celebrity } = series;
  const isMobile = useIsMobile();
  const { user } = useSession();
  checkSubscription(memberships, celebrity.id) || user?.id === celebrity.id;

  const video = episodes[episodeIndex];
  const sortedBanners = [...video?.bannerAds]
    .filter(banner => !banner.disable)
    .sort((a, b) => b.id - a.id);
  const latestBanner = sortedBanners[0];
  console.log(video?.widgetAds, 'widget add');
  const allWidgetAdImages = video?.widgetAds
    .filter(widget => !widget.disable)
    .flatMap(widget =>
      widget.widgetAdImages.map(image => ({
        ...image,
        widgetId: widget.id
      }))
    );
  const hasNext = useMemo(() => episodes.length > Number(episodeIndex) + 1, [
    episodes.length,
    episodeIndex
  ]);

  const nextEpisode = useMemo(() => hasNext && episodes[Number(episodeIndex) + 1], [
    hasNext,
    episodes,
    episodeIndex
  ]);

  const episodeHasNext = useCallback(index => episodes.length > index, [episodes.length]);

  const goToNext = currentId => {
    const currentEpisode = episodes.find(episode => episode.id === currentId);
    const currentIndex = episodes.findIndex(episode => episode.id === currentId);
    trackEvent(MIXPANEL_EVENTS.nextEpisode, {
      'Series name': title,
      'Current episode': currentEpisode.title
    });
    trackTiming();
    const { prevPath } = state || {};
    if (episodeHasNext(currentIndex)) {
      const nextEpisode = episodes[currentIndex];
      replace({
        pathname: routeWithProps(routesPaths.series, { id, episode: nextEpisode.id }),
        state: { prevPath }
      });
      window.location.reload();
    } else {
      // const { username } = celebrity;
      // history.push(routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() }));
      history.push(routesPaths.home);
    }
  };

  const playerRef = useRef(null);
  const [isBlurred, setIsBlurred] = useState(false);

  const applyBlur = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.style.filter = 'blur(20px)';
    }
    if (playerRef.current) {
      playerRef.current.pause();
    }
    setIsBlurred(true);
  }, []);

  const removeBlur = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.style.filter = 'none';
    }
    if (playerRef.current) {
      playerRef.current.play();
    }
    setIsBlurred(false);
  }, []);

  const handleBlur = useCallback(() => {
    applyBlur();
  }, [applyBlur]);

  const handleUserInteraction = useCallback(() => {
    if (isBlurred) {
      removeBlur();
    }
  }, [isBlurred, removeBlur]);

  useEffect(() => {
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('blur', handleBlur);
    };
  }, [handleBlur]);

  const videoJsOptions = useMemo(
    () => ({
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      aspectRatio: isMobile ? '9:16' : '16:8',
      sources: [
        {
          src: video.link,
          type: video.mimeType || 'video/mp4'
        }
      ],
      tracks: {
        id: video.episodeId,
        type: 'Series',
        seriesname: title,
        episodename: video.title
      }
    }),
    [isMobile, video.link]
  );
  const handlePlayNextUpdate = useCallback(
    newPlayNext => {
      console.log('PlayNext updated:', newPlayNext);
      if (newPlayNext) {
        if (episodeIndex + 1 === episodes.length) {
          // const route = routeWithProps(routesPaths.celebrityId, {
          //   username: user.username.toLowerCase()
          // });
          // goToWithSearch(route, `?tab=2`);
          history.push(routesPaths.home);
        } else {
          goToNext(episodes[episodeIndex + 1].id);
        }
      }
    },
    [episodeIndex, episodes, goToNext]
  );
  const { player, videoRef, IsAds, AdsDetail } = useVideoPlayer({
    options: videoJsOptions,
    onReady: useCallback(
      player => {
        playerRef.current = player;
      },
      [video.link, episodeIndex]
    ),
    preRollSource: video.videoAds?.find(ad => ad.placementOption === 'before_video' && !ad.disable),
    postRollSource: video.videoAds?.find(ad => ad.placementOption === 'after_video' && !ad.disable),
    midRollSource: video.videoAds?.find(
      ad => ad.placementOption === 'custom_intervals' && !ad.disable
    ),
    midRollInterval: video.videoAds?.find(
      ad => ad.placementOption === 'custom_intervals' && !ad.disable
    )?.specificInterval,
    customDurationAds: video.videoAds?.find(
      ad => ad.placementOption === 'specific_interval' && !ad.disable
    ),
    onPlayNextUpdate: handlePlayNextUpdate
  });
  const trackTiming = useCallback(() => {
    if (playerRef.current) {
      console.log('Trigger Events');
      trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
        Seconds: Math.round(playerRef.current.currentTime()), // Updated
        id,
        typeName: MIXPANEL_PARAMS.Episode
      });
    }
  }, [playerRef]);
  const handleBannerClick = useCallback(() => {
    if (latestBanner?.actionButtonUrl) {
      trackEvent(MIXPANEL_EVENTS.bannerAdClicked, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
      window.open(latestBanner.actionButtonUrl, '_blank');
    }
  }, [latestBanner]);

  useEffect(() => {
    if (latestBanner) {
      trackEvent(MIXPANEL_EVENTS.bannerAdViewed, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
    }
    if (allWidgetAdImages.length) {
      trackEvent(MIXPANEL_EVENTS.widgetAdViewed, {
        id: allWidgetAdImages[0].id,
        typeName: MIXPANEL_PARAMS.widgetAd
      });
    }
  }, []);

  const videoClicked = () => {
    if (IsAds && AdsDetail != null && AdsDetail.buttonText != null) {
      window.open(AdsDetail.actionButtonUrl, '_blank');
    }
  };

  return (
    <div
      className="slider-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        // overflow: 'hidden',
        flexDirection: 'column'
      }}
    >
      {((isMobile && latestBanner?.mobileBannerUrl) ||
        (!isMobile && latestBanner?.webBannerUrl)) && (
        <div
          className="banner-container"
          style={{
            width: '80vw',
            padding: isMobile ? '0.5rem 0' : '0.5rem',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
            zIndex: 10
          }}
        >
          <div
            className="banner-ad"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: latestBanner?.actionButtonUrl ? 'pointer' : 'default'
            }}
            onClick={() => (latestBanner?.actionButtonUrl ? handleBannerClick() : undefined)}
          >
            <img
              src={isMobile ? latestBanner?.mobileBannerUrl : latestBanner?.webBannerUrl}
              alt="banner-ad"
              style={{
                maxHeight: isMobile ? '120px' : '6vh',
                width: '80%'
                // objectFit: 'contain'
              }}
            />
          </div>
        </div>
      )}
      <div
        className="slider-content"
        style={{
          position: 'relative',
          width: '100%',
          height: isMobile ? 'calc(100% - 50px)' : 'calc(100% - 60px)',
          maxWidth: isMobile ? '100vw' : '80vw',
          maxHeight: isMobile ? '100vh' : '80vh',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          borderRadius: isMobile ? '0' : '0.5rem',
          display: 'flex',
          // overflow: 'hidden',
          flexDirection: 'column'
        }}
      >
        <div style={{ width: '100%', flexGrow: 1 }}>
          <div
            ref={containerRef}
            className={cn('video-container', {
              'video-ad-container': allWidgetAdImages.length !== 0,
              'video-ad-container-without-widget': allWidgetAdImages.length === 0,
              'no-pointer': isMobile
            })}
            onMouseMove={handleMouseMove}
            onClick={handleClick}
            style={{
              // marginTop: '5%',
              height: '100%',
              width: '100%'
            }}
          >
            <video ref={videoRef} className="video-js vjs-default-skin" onClick={videoClicked} />
            {isBlurred && (
              <div className="blur-overlay" onClick={handleUserInteraction}>
                <p>Click to resume video playback</p>
              </div>
            )}
            <div className="ad-overlay">
              {IsAds && AdsDetail != null && AdsDetail.buttonText != null ? (
                <div className="ad-content">
                  <div className="product-name">{AdsDetail.title}</div>
                  {AdsDetail.actionButtonUrl && (
                    <button
                      className="buy-now-button"
                      onClick={() => {
                        window.open(AdsDetail.actionButtonUrl, '_blank');
                      }}
                    >
                      {AdsDetail.buttonText}
                    </button>
                  )}
                </div>
              ) : null}
              {IsAds ? <div className="sponsored-label">Sponsored</div> : null}
            </div>
            {!IsAds && (
              <>
                <SeriesAdditionalControls
                  seriesTitle={title}
                  episodeTitle={video.title}
                  player={player}
                  hasNext={hasNext}
                  nextEpisode={nextEpisode}
                  goToNext={goToNext}
                  celebrity={celebrity}
                  creditsStartTime={video.creditsStartTime}
                  controlsVisible={controlsVisible}
                />
                {episodes[0] && (
                  <ActionableContents
                    videoTitle={video.title}
                    celebrity={celebrity}
                    disableSharing={false}
                    id={episodes[0]?.episodeId}
                    contentType="Episode"
                  />
                )}
              </>
            )}
          </div>
        </div>
        {console.log('episode', episodes)}

        {allWidgetAdImages.length ? (
          isMobile && allWidgetAdImages.length > 5 ? (
            <>
              <ImageSlider
                items={allWidgetAdImages.slice(0, Math.ceil(allWidgetAdImages.length / 2))}
                mobileView={isMobile}
                sliderId="slider1"
              />
              <ImageSlider
                items={allWidgetAdImages.slice(Math.ceil(allWidgetAdImages.length / 2))}
                mobileView={isMobile}
                sliderId="slider2"
              />
            </>
          ) : (
            <ImageSlider items={allWidgetAdImages} mobileView={isMobile} sliderId="slider1" />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

SeriesPlayer.propTypes = {
  series: shape(serieShape).isRequired,
  episodeIndex: oneOfType([string, number])
};

SeriesPlayer.defaultProps = {
  episodeIndex: 0
};

export default SeriesPlayer;
