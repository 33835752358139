/* eslint-disable radix */
import React, { useState, useMemo } from 'react';
import { shape, string } from 'prop-types';

import { useAnalytics, useCelebrity, useSession } from 'hooks';
import { celebrityShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS, celebrityTabs } from 'constants/constants';

import Tabs from 'components/common/Tabs/Tabs';
import OriginalsTab from 'components/ids/Originals/OriginalsTab';
import NarrativesTab from 'components/ids/Narratives/NarrativesTab';

import { getFullName, getPublishedNarratives } from 'utils/helpers';
import EventsTab from '../Events/EventsTab';

const CelebrityContent = ({
  celebrity: { firstName, lastName, series, narratives, id, username, events },
  tab
}) => {
  const { ORIGINALS, NARRATIVES, EVENTS } = celebrityTabs;
  const [selectedTab, setSelectedTab] = useState(parseInt(tab) || ORIGINALS.id);
  const { trackEvent } = useAnalytics();
  const { refresh } = useCelebrity(id);
  const celebrityName = getFullName(firstName, lastName);
  const {
    creatorLogged,
    user,
    user: { id: loggedId }
  } = useSession();
  const showOnlyPublished = useMemo(() => creatorLogged && user?.id !== id, [
    creatorLogged,
    user,
    id
  ]);
  const isLoggedUser = loggedId === id;

  const onTabSelect = id => {
    if (id == NARRATIVES.id) {
      trackEvent(MIXPANEL_EVENTS.narrativesListView, {
        CreatorId: celebrityName
      });
    }
    setSelectedTab(id);
  };

  const renderContent = tab => {
    switch (tab) {
      case ORIGINALS.id:
        return <OriginalsTab series={series} isLoggedUser={isLoggedUser} />;
      case NARRATIVES.id:
        return (
          <NarrativesTab
            narratives={!showOnlyPublished ? narratives : getPublishedNarratives(narratives)}
            celebrityId={id}
            celebrityName={celebrityName}
            refresh={refresh}
            username={username}
            isLoggedUser={isLoggedUser}
          />
        );
      case EVENTS.id:
        return (
          <EventsTab
            events={events}
            narratives={!showOnlyPublished ? narratives : getPublishedNarratives(narratives)}
            celebrityId={id}
            celebrityName={celebrityName}
            refresh={refresh}
            username={username}
            isLoggedUser={isLoggedUser}
          />
        );
      default:
    }
  };

  return (
    <div className="celebrity-content">
      <Tabs
        tabs={Object.values(celebrityTabs)}
        selectedTab={selectedTab}
        onTabSelect={onTabSelect}
      />
      {renderContent(selectedTab)}
    </div>
  );
};

CelebrityContent.propTypes = {
  celebrity: shape(celebrityShape),
  tab: string
};

export default CelebrityContent;
