/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import cn from 'classnames';
import ArrowRightIcon from 'assets/arrow-right.png';
import { LogoIcon } from 'components/icons';
import { useNavigation } from 'hooks';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';

const NoAds = () => {
  const { goTo } = useNavigation();
  const handleGoToPlans = () => {
    goTo(
      routeWithProps(routesPaths.account, {
        tab: 'plans'
      })
    );
  };
  return (
    <div className={cn('no-ads-container')}>
      <div className="container">
        <div className="icon">
          <LogoIcon className="identifi-logo" />
        </div>
        <h2>Welcome to Identifi Ad manager</h2>
        <p className="description">
          Please explore plans and subscribe to insert and manage your own ads in your Series,
          Shorts, Events and Poadcasts
        </p>
        <a onClick={handleGoToPlans} className="go-button">
          GO TO PLANS <img src={ArrowRightIcon} height="20px" width="20px" alt="arrow" />
        </a>
      </div>
    </div>
  );
};

export default NoAds;
