import { createReducer } from '@rootstrap/redux-tools';
import { getOneSeries, getTrailerUrlForJWPlayer } from 'state/actions/seriesActions';

import { filterEpisodes } from 'utils/dataHelpers';

const initialState = {
  seriesList: [],
  featuredSeries: [],
  trailerData: null
};

export default createReducer(initialState, {
  [getOneSeries.success]: (state, { payload }) => {
    state.series = { ...payload, episodes: filterEpisodes(payload.episodes) };
  },
  [getTrailerUrlForJWPlayer.success]: (state, { payload }) => {
    state.trailerData = payload;
  },
  [getTrailerUrlForJWPlayer.reset]: state => {
    state.trailerUrl = null;
  }
});
