import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { bool, func } from 'prop-types';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import useProfile from 'hooks/users/useProfile';

import MembershipCard from 'components/membership/MembershipCard/MembershipCard';
import EmptyMessage from 'components/common/EmptyMessage/EmptyMessage';
// import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import useMembership from 'hooks/celebrities/useMembership';

import { MEMBERSHIP_STATUSES, subscriptionTabs } from 'constants/constants';
import { getIdFromTab } from 'utils/dataHelpers';
import Tabs from 'components/common/Tabs/Tabs';
import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import SubscriberMembershipCard from 'components/membership/MembershipCard/SubscriberMemberShipCard';
import AdMembershipCard from 'components/membership/MembershipCard/AdMembershipCard';
import EventSubscriptionCard from 'components/membership/MembershipCard/EventSubscriptionCard';
import { USER_TYPES } from 'hooks/useSession';

const { SUBSCRIPTIONS, SUBSCRIBERS, AD_MEMBERSHIP, EVENTS } = subscriptionTabs;

const Subscriptions = ({ isModal, hide }) => {
  const intl = useIntl();
  const {
    profile: { type, memberships, subscribers, adMemberships, subscribedEvents }
  } = useProfile();
  const { cancelMembership } = useMembership();

  const { INACTIVE } = MEMBERSHIP_STATUSES;

  const { tab } = useParams();
  const selectedTabId = useMemo(() => tab && getIdFromTab(subscriptionTabs, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(
    selectedTabId || (type === USER_TYPES.user ? SUBSCRIPTIONS.id : SUBSCRIBERS.id)
  );

  const activeMemberships = useMemo(
    () => memberships?.filter(({ status }) => status !== INACTIVE) || [],
    [memberships, INACTIVE]
  );

  const activeSubscribers = useMemo(
    () => subscribers?.filter(({ status }) => status !== INACTIVE) || [],
    [subscribers, INACTIVE]
  );

  const activeAdMemberships = useMemo(
    () => adMemberships?.filter(({ status }) => status !== INACTIVE) || [],
    [adMemberships, INACTIVE]
  );

  const activeEvents = useMemo(
    () => subscribedEvents?.filter(({ status }) => status !== INACTIVE) || [],
    [subscribedEvents, INACTIVE]
  );

  const tabsWithCount = useMemo(() => {
    const allTabs = [
      { ...SUBSCRIBERS, count: activeSubscribers.length },
      { ...SUBSCRIPTIONS, count: activeMemberships.length },
      { ...AD_MEMBERSHIP, count: activeAdMemberships.length },
      { ...EVENTS, count: activeEvents.length }
    ];

    return type === USER_TYPES.user
      ? allTabs.filter(tab => tab.id !== SUBSCRIBERS.id && tab.id !== AD_MEMBERSHIP.id)
      : allTabs;
  }, [
    type,
    activeMemberships.length,
    activeSubscribers.length,
    activeAdMemberships.length,
    activeEvents.length
  ]);

  const onTabChange = id => {
    setSelectedTab(id);
  };

  const renderContent = () => {
    if (selectedTab === 2) {
      return (
        activeMemberships && (
          <>
            <div className="subscriptions-container">
              {activeMemberships.map(membership => (
                <MembershipCard
                  membership={membership}
                  key={membership.id}
                  cancelMembership={cancelMembership}
                />
              ))}
            </div>
            {isEmpty(activeMemberships) &&
              (isModal ? (
                <EmptyMessage titleId="subscriptions.empty" />
              ) : (
                <h5 className="empty-title">{intl.formatMessage({ id: 'subscriptions.empty' })}</h5>
              ))}
          </>
        )
      );
    }
    if (selectedTab === 3) {
      return (
        activeAdMemberships && (
          <>
            <div className="subscriptions-container">
              {activeAdMemberships.map(membership => (
                <AdMembershipCard
                  membership={membership}
                  key={membership.id}
                  cancelMembership={cancelMembership}
                />
              ))}
            </div>
            {isEmpty(activeAdMemberships) &&
              (isModal ? (
                <EmptyMessage titleId="adMembership.empty" />
              ) : (
                <h5 className="empty-title">{intl.formatMessage({ id: 'adMembership.empty' })}</h5>
              ))}
          </>
        )
      );
    }
    if (selectedTab === 4) {
      return (
        activeEvents && (
          <>
            <div className="subscriptions-container">
              {activeEvents.map(item => {
                return (
                  <EventSubscriptionCard
                    event={item.event}
                    celebrity={item.celebrity}
                    key={item.id}
                  />
                );
              })}
            </div>
            {isEmpty(activeEvents) &&
              (isModal ? (
                <EmptyMessage titleId="events.empty" />
              ) : (
                <h5 className="empty-title">{intl.formatMessage({ id: 'events.empty' })}</h5>
              ))}
          </>
        )
      );
    }
    return (
      activeSubscribers && (
        <>
          <div className="subscriptions-container">
            {activeSubscribers.map(membership => (
              <SubscriberMembershipCard
                membership={membership}
                key={membership.id}
                cancelMembership={cancelMembership}
                isSubscriber
              />
            ))}
          </div>
          {isEmpty(activeSubscribers) &&
            (isModal ? (
              <EmptyMessage titleId="subscribers.empty" />
            ) : (
              <h5 className="empty-title">{intl.formatMessage({ id: 'subscribers.empty' })}</h5>
            ))}
        </>
      )
    );
  };

  return (
    <div className={cn('subscriptions', { 'subscriptions-modal': isModal })}>
      {isModal && <ModalHeader titleId="subscriptions.title" hideModal={hide} />}
      <Tabs
        tabs={tabsWithCount}
        selectedTab={selectedTab}
        onTabSelect={onTabChange}
        className="subscription-tabs"
      />
      {renderContent()}
    </div>
  );
};

Subscriptions.propTypes = {
  isModal: bool,
  hide: func
};

export default Subscriptions;
