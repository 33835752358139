/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { getHome } from 'state/actions/homeActions';
import { useDispatch, useAnalytics, useHome, useSession } from 'hooks';
import { useSeries } from 'hooks/useSeries';
import useProfile from 'hooks/users/useProfile';
import useShowPushNotification from 'hooks/notifications/useShowPushNotification';
import { MIXPANEL_EVENTS } from 'constants/constants';

import FeaturedHero from 'components/featured/FeaturedHero/FeaturedHero';
import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';
import CategorySection from 'components/categoryList/CategorySection/CategorySection';
import KeepWatchingSection from 'components/series/KeepWatchingSection/KeepWatchingSection';
import Loading from 'components/common/Loading';

import { getFullName } from 'utils/helpers';

const HomePage = () => {
  const { trackEvent } = useAnalytics();
  const { initializePushNotifications } = useShowPushNotification();

  useEffect(() => {
    initializePushNotifications();
  }, []);

  const getHomeRequest = useDispatch(getHome);
  const { keepWatchingList } = useSeries();
  const { authenticated } = useSession();

  const { celebrities, featuredCategories, content, loading } = useHome();

  const { requestProfile } = useProfile();

  useEffect(() => {
    !content?.length && getHomeRequest();
    authenticated && requestProfile();
    trackEvent(MIXPANEL_EVENTS.home);
  }, []);

  // Saved watched episodes match what came from backend
  const keepWatchingFiltered = keepWatchingList.filter(
    element => content.findIndex(({ id, liveEvent }) => id == element.id || !liveEvent) !== -1
  );

  const onCelebrityClick = celebrity => {
    const { firstName, lastName } = celebrity;
    trackEvent(MIXPANEL_EVENTS.featuredCreator, { creatorName: getFullName(firstName, lastName) });
  };

  if (loading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <FeaturedHero content={content} />
      {keepWatchingFiltered && keepWatchingFiltered.length && (
        <KeepWatchingSection series={keepWatchingFiltered} />
      )}
      <FeaturedCelebrities
        celebrities={celebrities}
        titleId="featured.creators"
        onCelebrityClick={onCelebrityClick}
      />
      {featuredCategories.map(category => (
        <CategorySection category={category} key={category.id} />
      ))}
    </>
  );
};

HomePage.loadData = async store => {
  await store.dispatch(getHome());
};

export default HomePage;
