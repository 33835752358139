import httpClient from 'httpClient';
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from './userService';

class EventService {
  static addEvent(celebrityId, event) {
    return httpClient.post(`/celebrities/${celebrityId}/events`, event, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => event
    });
  }

  static getEventById(celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/events`);
  }

  static deleteEvent(celebrityId, eventId) {
    return httpClient.delete(`/celebrities/${celebrityId}/events/${eventId}`);
  }

  static getEventByEventId(eventId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/events/${eventId}`);
  }

  static updateEvent(celebrityId, eventId, event) {
    return httpClient.put(`/celebrities/${celebrityId}/events/${eventId}`, event, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => event
    });
  }
}

export default EventService;
