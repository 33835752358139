import React, { memo } from 'react';
import { func, object, string } from 'prop-types';
import cn from 'classnames';

const IconButton = ({ onClick, icon, className }) => (
  <div className={cn('button secondary icon-button', className)} onClick={onClick}>
    {icon}
  </div>
);

IconButton.propTypes = {
  onClick: func.isRequired,
  icon: object.isRequired,
  className: string
};

export default memo(IconButton);
