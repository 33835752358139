import React from 'react';
import { number } from 'prop-types';

const AdFree = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.46094 21.5731L19.4498 2.56436"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.45212 21.3525C12.4278 22.8619 17.0849 21.7052 19.8555 18.1877C22.6261 14.6701 22.6595 9.87162 20.2608 6.36021M3.73864 17.6399C1.33993 14.1285 1.37334 9.33001 4.14393 5.81246C6.91452 2.29492 11.5717 1.13829 15.5473 2.64764"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

AdFree.propTypes = {
  width: number,
  height: number
};

export default AdFree;
