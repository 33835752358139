/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { shape, bool, func } from 'prop-types';
import cn from 'classnames';
import { routesPaths } from 'constants/routesPaths';
import { celebrityShape, serieShape } from 'constants/propTypesShapes';
import { routeWithProps } from 'utils/helpers';
import { PlayIcon, ArrowIcon, InfoIcon, TrashIcon, OptionsIcon, EditIcon } from 'components/icons';
import Button from 'components/common/Button/Button';
import IconButton from 'components/common/Button/IconButton';
import VideoQuickPlayer from 'components/Players/VideoQuickPlayer';
import SerieInfo from 'components/series/SerieInfo/SerieInfo';
import { useClickOutside, useModal, useNavigation } from 'hooks';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import DeletePublication from 'components/DeletePublication/DeletePublication';
import Modal from 'components/common/Modal/Modal';
import ReportModal from 'components/ReportModal/ReportModal';

const CLOSED = 0;
const OPENED = 1;
const SerieBannerDesktop = ({
  serie,
  showEpisodes,
  toggleShowEpisodes,
  onClickPlay,
  playVideo,
  toggleTrailer,
  toggleLanding,
  withBorder,
  mimeType,
  refresh,
  celebrityId,
  isLoggedUser
}) => {
  const { bannerUrl } = serie;
  const { goTo } = useNavigation();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const { toggle, isShowing } = useModal();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);
  const handleEditSeries = () => {
    goTo(
      routeWithProps(routesPaths.editOriginals, {
        seriesId: serie.id,
        celebrityId
      })
    );
  };
  return (
    <div className={cn('serie-banner', 'max-width', 'desktop-only', { separator: !showEpisodes })}>
      <div className="main-content">
        <div className={`serie-banner-preview ${withBorder ? 'series-banner-yellow' : ''}`}>
          <div onClick={toggleTrailer}>
            <VideoQuickPlayer
              videoUrl={bannerUrl}
              autoPlay={playVideo}
              withBorder={withBorder}
              type={mimeType}
            />
            {withBorder && <div className="processing-overlay">PROCESSING</div>}
          </div>
          <div className="sharing-options" ref={ref}>
            {optionsStatus === OPENED && (
              <>
                {!isLoggedUser && (
                  <button className="report-button" onClick={reportToggle}>
                    Report
                  </button>
                )}
                {isLoggedUser && (
                  <IconWithBackground icon={<TrashIcon />} onClick={toggle} isSmall />
                )}
                {isLoggedUser && (
                  <IconWithBackground
                    icon={<EditIcon />}
                    onClick={() => handleEditSeries()}
                    isSmall
                  />
                )}
                {/* <IconWithBackground
                  icon={<ShareIcon />}
                  onClick={() => setOptionsStatus(ICONS_DISPLAYED)}
                  isSmall
                /> */}
              </>
            )}
            {(optionsStatus === CLOSED || optionsStatus === OPENED) && (
              <IconWithBackground
                icon={<OptionsIcon />}
                onClick={() => setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED)}
                isSmall
              />
            )}
            {isShowing && (
              <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
                <DeletePublication
                  type="series"
                  refresh={refresh}
                  celebrityId={celebrityId}
                  id={serie.id}
                />
              </Modal>
            )}
            {reportShowing && (
              <Modal
                hide={reportToggle}
                isShowing={reportShowing}
                topModal
                zIndex={zIndexModalThird}
                className="original-modal"
              >
                <ReportModal title="Report Content" type="isSeries" id={serie.id} />
              </Modal>
            )}
          </div>
        </div>
        <div className="serie-banner-info">
          <SerieInfo serie={serie} className="serie-info-large" />
          <div className="serie-banner-info-buttons">
            <Button
              labelId="episodes.first"
              onClick={onClickPlay}
              type="primary"
              icon={<PlayIcon />}
              disabled={serie.episodes.length === 0}
            />
            <Button
              labelId="episodes.all"
              onClick={toggleShowEpisodes}
              type="secondary"
              size="small"
              icon={<ArrowIcon type={showEpisodes ? 'up' : 'down'} />}
              className="episodes-button"
            />
            <IconButton onClick={toggleLanding} icon={<InfoIcon />} />
          </div>
        </div>
      </div>
    </div>
  );
};

SerieBannerDesktop.propTypes = {
  serie: shape(serieShape).isRequired,
  showEpisodes: bool,
  toggleShowEpisodes: func.isRequired,
  onClickPlay: func.isRequired,
  toggleTrailer: func.isRequired,
  toggleLanding: func.isRequired,
  playVideo: bool,
  withBorder: bool,
  refresh: func,
  celebrityId: shape(celebrityShape),
  isLoggedUser: bool
};

export default SerieBannerDesktop;
