import React, { useMemo } from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button/Button';
import CreditCard from 'components/payments/CreditCard/CreditCard';
import NewCardForm from 'components/payments/NewCardForm/NewCardForm';
import ConfirmPayment from 'components/payments/ConfirmPayment/ConfirmPayment';
import PurchaseModalHeader from 'components/payments/PurchaseModalHeader/PurchaseModalHeader';

import { PURCHASE_TYPES } from 'constants/constants';
import { celebrityShape, scheduledLiveEventShape } from 'constants/propTypesShapes';

import { useClickOutside } from 'hooks';
import useEpisode from 'hooks/useEpisode';
import useMembership from 'hooks/celebrities/useMembership';
import useSubscribeContext from 'hooks/account/useSubscribeContext';

import useModalInfo from './useModalInfo';
import usePurchaseModal from './usePurchaseModal';

const PurchaseModal = ({ celebrity, liveEvent, hide, onSuccess }) => {
  const ref = useClickOutside(hide);
  const intl = useIntl();
  const { goToChangePaymentMethod } = useSubscribeContext();
  const { membershipPlans } = useMembership();
  const { episode, flowConfig } = useEpisode();

  const { purchaseType } = flowConfig || {};

  const { modalInfo, showConfirmation, setShowConfirmation, creditCards } = usePurchaseModal(
    celebrity,
    liveEvent
  );

  const { title, subtitle, price: episodePrice, image } = useModalInfo({
    purchaseType,
    modalInfo,
    episode,
    celebrity
  });

  const defaultCard = creditCards[0];

  const MEMBERSHIP_DETAILS = membershipPlans[0];

  const PURCHASE_MEMBERSHIP_EPISODE = purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE;

  const PURCHASE_ITEMS = useMemo(() => {
    return PURCHASE_MEMBERSHIP_EPISODE
      ? [
          {
            title: intl.formatMessage({ id: 'membership.title' }),
            description: intl.formatMessage(
              { id: 'membership.price' },
              { price: MEMBERSHIP_DETAILS.price }
            ),
            price: intl.formatMessage(
              { id: 'membership.monthly.price' },
              { price: MEMBERSHIP_DETAILS.price }
            )
          },
          {
            title: intl.formatMessage({ id: 'general.episode' }),
            description: intl.formatMessage({ id: 'unlock.episode.payment' }),
            price: `$${episodePrice}`,
            withBorder: true
          },
          {
            title: intl.formatMessage({ id: 'general.total' }),
            description: null,
            price: `$${MEMBERSHIP_DETAILS.price + episodePrice}`,
            withBorder: true
          }
        ]
      : [];
  }, [MEMBERSHIP_DETAILS, PURCHASE_MEMBERSHIP_EPISODE, episodePrice, intl]);

  return (
    <div ref={ref} className="purchase-modal">
      <PurchaseModalHeader
        title={title}
        subtitle={subtitle}
        image={image}
        price={episodePrice}
        showProfile={!!celebrity}
        hide={hide}
        purchaseItems={PURCHASE_ITEMS}
      />
      {showConfirmation && !!defaultCard && (
        <ConfirmPayment
          celebrity={celebrity}
          creditCard={defaultCard}
          liveEvent={liveEvent}
          hide={hide}
          onSuccess={onSuccess}
          purchaseType={purchaseType}
        />
      )}
      {!showConfirmation &&
        (!defaultCard ? (
          <NewCardForm />
        ) : (
          <div className="purchase-modal-payment">
            <div className="checkout-header">
              <h4 className="h4-medium">{intl.formatMessage({ id: 'paymentMethod.title' })}</h4>
              <div className="secondary-action" onClick={goToChangePaymentMethod}>
                <h6 className="bold">{intl.formatMessage({ id: 'payments.changeMethod' })}</h6>
              </div>
            </div>
            <CreditCard card={defaultCard} isDefault />
            <div className="submit-container">
              <Button
                onClick={() => setShowConfirmation(true)}
                labelId="general.continue"
                type="primary"
                size="xsmall"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

PurchaseModal.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  liveEvent: shape(scheduledLiveEventShape),
  hide: func,
  onSuccess: func
};

export default PurchaseModal;
